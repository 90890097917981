import React from "react";
import PropTypes from "prop-types";

const emptyTableStyles = {
  display: "flex",
  backgroundColor: "#ffffff",
  alignItems: "center",
  height: "100%",
};

const messageStyles = {
  flex: "1 1 auto",
  textAlign: "center",
};

const h3Styles = {
  fontWeight: 400,
};

const EmptyTable = ({ className, message }) => (
  <div style={emptyTableStyles} className={className}>
    <div style={messageStyles}>
      <h3 style={h3Styles} className="p-4 text-baby-boy-blue">
        {message}
      </h3>
    </div>
  </div>
);

EmptyTable.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

EmptyTable.defaultProps = {
  className: "",
  message: "There is no available information to display.",
};

export default EmptyTable;
