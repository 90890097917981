import { useFind } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useFindEmrUser = (user_uuid) => {
  const { authToken } = useAuthContext();
  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.AUTH_API_BASE_URL,
    authToken,
  );

  return useFind(
    "emr_user",
    {
      user_uuid: user_uuid,
    },
    jsonApiAdapter,
  );
};
