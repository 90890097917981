import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useNavigate } from "react-router";
import { Icon } from "@unite-us/ui";

const Breadcrumbs = ({ crumbsData, className, dataTestId }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classnames("flex flex-wrap", className)}
      data-testid={dataTestId}
    >
      {crumbsData.map(({ label, href }, index) => {
        const isLast = index === crumbsData.length - 1;
        const crumbLabel = label || "Loading...";
        const crumbs =
          href && !!label ? (
            <span
              className={
                "hover:text-medium-grey cursor-pointer text-action-blue"
              }
              onClick={() => navigate(href)}
            >
              {crumbLabel}
            </span>
          ) : (
            <span>{crumbLabel}</span>
          );
        return (
          <div className="flex items-center" key={`${label}-${index}`}>
            {crumbs}
            {!isLast && (
              <Icon
                className="mx-4 opacity-50 fill-current text-dark-grey"
                icon="IconChevronRight"
                size={12}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  crumbsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
  ).isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  className: "",
  dataTestId: "",
};

export default Breadcrumbs;
