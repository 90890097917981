import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "components/ProgressBar/ProgressBar";

const BatchProgress = ({ totalJobs, finishedJobs, status }) => {
  const progress = (finishedJobs / totalJobs) * 100;
  const isBatchEmpty = finishedJobs === 0 && status !== "processing";
  return (
    <div className="w-full h-full" data-testid="batch-progress-container">
      {isBatchEmpty ? (
        <div className="font-bold text-center">No Jobs Found</div>
      ) : (
        <>
          <div className="font-bold text-center">Current Status:</div>
          <p>
            {totalJobs === finishedJobs
              ? `Finished processing all jobs (${totalJobs})`
              : `Processing job ${finishedJobs + 1} of ${totalJobs}`}
          </p>
          <ProgressBar progress={progress} />
        </>
      )}
    </div>
  );
};

BatchProgress.propTypes = {
  totalJobs: PropTypes.number.isRequired,
  finishedJobs: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default BatchProgress;
