import { filterRoles } from "pages/users/utils/filterRoles.js";

export const getEmployeeRoleDetails = (employeeRecords, rolesResponse) => {
  if (
    !employeeRecords ||
    employeeRecords.length === 0 ||
    !rolesResponse ||
    rolesResponse.length === 0 ||
    Object.keys(employeeRecords[0]).length === 0 ||
    Object.keys(rolesResponse[0]).length === 0
  ) {
    return [];
  }
  const userRoles = employeeRecords.map((records) => records.roles);
  const employeeRoleDetails = userRoles.map((employeeRoles) => {
    return employeeRoles.map((employeeRole) => {
      const foundRoleDetail = rolesResponse.find(
        (role) => role.id === employeeRole.id,
      );
      return foundRoleDetail;
    });
  });
  return employeeRoleDetails.map((roles) => filterRoles(roles));
};
