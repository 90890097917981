import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BaseCard, BaseCardHeader, BaseCardBody } from "@unite-us/ui";
import CardRow from "components/CardRow";
import { useNetworks } from "api/core/networkHooks";

const PlanOverview = ({ plan }) => {
  const {
    name,
    external_id,
    starts_at,
    ends_at,
    plan_type,
    state,
    enrollment_required,
    coverage_period,
    networks,
  } = plan;
  const networkIds = networks?.map((network) => network.id).join(",");
  const { data: networksResponse, isFetchingNetworks } = useNetworks({
    filters: { id: networkIds },
  });
  const { data: networksData } = networksResponse;

  return (
    <BaseCard>
      <BaseCardHeader
        title="Plan Details"
        className="font-bold !bg-medium-fill-grey"
      />
      <BaseCardBody withPadding className="flex flex-col bg-white">
        <CardRow
          title="Plan Name:"
          content={name}
          capitalize
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="External ID:"
          content={external_id}
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="Start Date:"
          content={starts_at ? moment(starts_at).format("MM/DD/YYYY") : ""}
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="End Date:"
          content={ends_at ? moment(ends_at).format("MM/DD/YYYY") : ""}
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="Plan Type:"
          content={plan_type}
          capitalize
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="State:"
          content={state}
          capitalize
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="Enrollment:"
          content={enrollment_required ? "Required" : "Not Required"}
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        <CardRow
          title="Coverage Period:"
          content={coverage_period}
          divider={false}
          className="py-2"
          spaceBetweenTitleAndContent="xs"
        />
        {plan.plan_type === "social" && (
          <CardRow
            title="Networks:"
            content={
              isFetchingNetworks
                ? "Loading networks..."
                : networksData?.map((network) => network.name).join(", ")
            }
            divider={false}
            className="py-2"
            spaceBetweenTitleAndContent="xs"
          />
        )}
      </BaseCardBody>
    </BaseCard>
  );
};

PlanOverview.propTypes = {
  plan: PropTypes.shape({
    name: PropTypes.string,
    external_id: PropTypes.string,
    starts_at: PropTypes.string,
    ends_at: PropTypes.string,
    plan_type: PropTypes.string,
    state: PropTypes.string,
    enrollment_required: PropTypes.bool,
    coverage_period: PropTypes.number,
  }),
};

export default PlanOverview;
