import React from "react";
import { AppBar } from "@unite-us/ui";
import LeftNav from "./LeftNav";
import RightNav from "./RightNav";

const NavBar = () => {
  return (
    <div className="fixed top-0 z-50 w-full">
      <AppBar
        key="mission-control-appbar"
        iconElementLeft={<LeftNav dataTestId="left-nav" />}
        opened={false}
        iconElementRight={<RightNav dataTestId="right-nav" />}
      />
    </div>
  );
};

export default NavBar;
