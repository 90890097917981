import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Menu, Button, MenuItem, Icon } from "@unite-us/ui";

const RightNav = ({ dataTestId }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center" data-testid={dataTestId}>
      <Menu
        position="notification"
        className="p-0"
        anchorElement={
          <Button
            id="menu-button"
            primary
            className="bg-placeholder-blue text-white text-sm w-28 hover:bg-placeholder-blue"
            label="Menu"
          />
        }
      >
        <MenuItem
          onClick={() => navigate("payers")}
          primaryNode={
            <div className="flex items-center gap-2">
              <Icon icon="Benefits" />
              <span>Payers</span>
            </div>
          }
        />
        <MenuItem
          onClick={() => navigate("revoke-consent")}
          primaryNode={
            <div className="flex items-center gap-2">
              <Icon icon="IconCross" />
              <span>Revoke Consent</span>
            </div>
          }
        />
      </Menu>
    </div>
  );
};

RightNav.propTypes = {
  dataTestId: PropTypes.string,
};

RightNav.defaultProps = {
  dataTestId: "",
};

export default RightNav;
