import React, { useState } from "react";
import { useFindPayer } from "api/core/payerHooks";
import { useParams } from "react-router";
import Spinner from "components/Spinner/Spinner";
import PayerHeader from "./components/PayerHeader";
import PlansTable from "./components/PlansTable";

const Payer = () => {
  const [location, setLocation] = useState("plans");
  const {
    data: payerResponse,
    isFetching,
    isError,
  } = useFindPayer(useParams().payerId);
  const payer = payerResponse?.data?.data || {};
  const crumbs = [{ label: "Payers", href: "/payers" }, { label: payer.name }];
  const planIds = payer?.plans?.map((plan) => plan.id);
  const shouldRenderTabComponent = !isFetching && !isError;

  const handleTabChange = (value) => {
    setLocation(value);
  };

  return (
    <>
      <PayerHeader
        handleTabChange={handleTabChange}
        location={location}
        payer={payer}
        crumbs={crumbs}
      />

      <div className="px-20 py-8">
        {isFetching && <Spinner center />}
        {isError && <div>There was an error fetching payer</div>}
        {shouldRenderTabComponent && location === "plans" && (
          <PlansTable planIds={planIds} />
        )}
      </div>
    </>
  );
};

export default Payer;
