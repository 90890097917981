import React, { useRef, useState } from "react";
import SearchBar from "components/SearchBar/SearchBar";
import { Button } from "@unite-us/ui";
import CustomTable from "components/Tables/CustomTable";
import { consentStatuses } from "./utils/consentStatuses";
import { get } from "lodash";
import DialogV2 from "components/DialogV2/DialogV2";
import RevokeConsentContent from "./components/RevokeConsentContent";
import { isRequired } from "utils/validations";
import moment from "moment";
import {
  useContactHqUserOnly,
  useRevokeConsent,
} from "api/core/revokeConsentHooks";

export const RevokeConsent = () => {
  const [selectedContact, setSelectedContact] = useState({});
  const [dialogContent, setDialogContent] = useState(<></>);
  const [personId, setPersonId] = useState("");

  const revokeConsentDialogRef = useRef(null);
  const { updateRecord: revokeConsent } = useRevokeConsent();

  const contactResponse = useContactHqUserOnly(personId);
  const { data: contactData, isFetching, isError } = contactResponse;

  const confirmRevoke = async (contact) => {
    const date = get(document.getElementById("revoked-date-field"), "value");

    if (!date || date === "Invalid date") {
      isRequired();
    } else {
      revokeConsentDialogRef.current.closeDialog();
      try {
        await revokeConsent(contact.consent.id, {
          state: "revoked",
          revoked_on: moment().utc(date).format("YYYY-MM-DD"),
        });
      } catch (error) {
        console.error("Error revoking consent: ", error);
      }
    }
  };

  const handleOnSubmitSearchBar = (data) => {
    setPersonId(data?.search);
  };

  const cancelRevoke = () => {
    revokeConsentDialogRef.current.closeDialog();
  };

  const deleteButtonOnClickHandler = (e, contact) => {
    e.stopPropagation();
    setDialogContent(RevokeConsentContent(contact));
    setSelectedContact(contact);
    revokeConsentDialogRef.current.openDialog();
  };

  const tableHeaders = [
    { label: "Client Name" },
    { label: "Client ID" },
    { label: "Consent Status" },
    { label: "Action" },
  ];

  const tableBody = contactData?.id
    ? [
        {
          rowId: contactData.id,
          rowData: [
            {
              data: `${contactData.first_name} ${contactData.last_name}`,
              className: "text-left text-sm font-medium w-1/10",
            },
            { data: contactData.id },
            {
              data: contactData.consent
                ? consentStatuses[contactData.consent.state]
                : consentStatuses[["needs-consent"]],
            },
            {
              data:
                contactData.consent.state === "accepted" ? (
                  <Button
                    data-testid="revoke-consent-button"
                    id={`revoke-consent-${contactData.consent.id}-button`}
                    className="py-1 px-4 text-xs text-current-black rounded
          border-action-blue border-opacity-25 revoke-consent-btn"
                    label="Revoke Consent"
                    onClick={(e) => deleteButtonOnClickHandler(e, contactData)}
                  />
                ) : (
                  "---"
                ),
            },
          ],
        },
      ]
    : [];

  return (
    <div className="py-10 px-20">
      <div
        className="flex pb-2"
        id="TopOfContacts"
        data-testid="revoke-consent-header"
      >
        <div className="w-full flex flex-row relative">
          <h1 className="pt-1 font-bold text-center pr-5">Revoke Consent</h1>
          <SearchBar
            onSubmit={handleOnSubmitSearchBar}
            dataTestId="revoke-consent-client-id-field"
            placeholder="Enter Client ID"
            delay={500}
          />
        </div>
      </div>
      {personId && (
        <div
          className="contacts-table flex flex-col"
          data-testid="contacts-table-container"
        >
          <div className="overflow-x-auto">
            <div className="py-2 align-middle">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
                <CustomTable
                  headers={tableHeaders}
                  body={tableBody}
                  isFetching={isFetching}
                  isError={isError}
                  className="flex flex-col min-w-full max-h-[55vh]"
                  emptyTableMessage="No Client Found"
                  errorMessage="Error Fetching Client"
                />
                <DialogV2
                  confirmButtonTestId="confirm-revoke-btn"
                  dataTestId="revoke-consent-dialog"
                  cancelHandler={cancelRevoke}
                  confirmLabel="Revoke Consent"
                  confirmationHandler={() => confirmRevoke(selectedContact)}
                  ref={revokeConsentDialogRef}
                  scrollable={false}
                  title="Revoke Consent for Selected Client"
                >
                  {dialogContent}
                </DialogV2>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
