import React from "react";
import Cookies from "js-cookie";

export const AuthLogout = ({ authUrl, cookieSessionKey }) => {
  const cookie = Cookies.get(cookieSessionKey);

  if (!cookie) {
    return null;
  }

  const { access_token } = JSON.parse(cookie);
  const encodedSession = window.btoa(JSON.stringify({ token: access_token }));

  return (
    <form
      action={`${authUrl}/logout`}
      method="POST"
      data-testid="auth-logout-component"
      id="logout-auth-form"
    >
      <input
        type="hidden"
        data-testid="hidden-logout-input"
        id="session"
        name="session"
        value={encodedSession}
      />
    </form>
  );
};
