import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useFindPayer } from "api/core/payerHooks";
import { useFindPlan } from "api/core/planHooks";
import Spinner from "components/Spinner/Spinner";
import { setPlanTabLocation, getPlanTabLocation } from "utils/localStorage";
import PlanOverview from "./components/PlanOverview";
import PlanAttachments from "./components/PlanAttachments";
import PlanEnrollments from "./components/PlanEnrollments";
import PlanHeader from "./components/PlanHeader";

const Plan = () => {
  const { payerId, planId } = useParams();
  const [location, setLocation] = useState(getPlanTabLocation() || "overview");
  const { data: payerResponse } = useFindPayer(payerId);
  const payer = payerResponse?.data?.data || {};
  const {
    data: planResponse,
    isFetching: isFetchingPlan,
    isError: isErrorPlan,
  } = useFindPlan(planId);
  const plan = planResponse?.data?.data || {};
  const shouldRenderTabComponent = !isFetchingPlan && !isErrorPlan;

  useEffect(() => {
    setPlanTabLocation(location);
  }, [location]);

  const crumbsData = [
    { href: "/payers", label: "Payers" },
    {
      href: `/payers/${payerId}`,
      label: payer.name,
    },
    { label: "Plans" },
    {
      label: plan.name,
    },
  ];

  const handleTabChange = (value) => {
    setLocation(value);
  };

  return (
    <>
      <PlanHeader
        handleTabChange={handleTabChange}
        location={location}
        plan={plan}
        payerId={payerId}
        crumbs={crumbsData}
      />

      <div className="px-20 pt-8">
        {isFetchingPlan && <Spinner center />}
        {isErrorPlan && <div>There was an error fetching the plan</div>}
        {shouldRenderTabComponent && location === "overview" && (
          <PlanOverview plan={plan} />
        )}
        {shouldRenderTabComponent && location === "attachments" && (
          <>
            {plan.plan_type === "social" && <PlanEnrollments plan={plan} />}
            <PlanAttachments plan={plan} />
          </>
        )}
      </div>
    </>
  );
};

export default Plan;
