import { useEffect, useRef, useState } from "react";
import { useBatchData } from "api/core/batchDataHooks";
import { useEmployees } from "api/core/employeeHooks";
import { Button, Icon, Badge } from "@unite-us/ui";
import { useQueryClient } from "react-query";
import {
  getStatusBadgeClassname,
  getStatusBadgeColor,
  getStatusBadgeStyle,
} from "./utils/statusUtils";
import { getBatchAction } from "./utils/getBatchAction";
import { getLocalDateTimeWithTimeZone } from "utils/date";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Modal } from "@unite-us/app-components";
import BatchDetails from "./components/BatchDetails";

export const ImportCenter = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();
  const modalRef = useRef(null);
  const [selectedBatchDetails, setSelectedBatchDetails] = useState({
    batchId: null,
    dateCreated: null,
    requester: null,
    action: null,
    batchSize: null,
    status: null,
  });

  const { data: batchesResponse, isFetching: isFetchingBatches } = useBatchData(
    {
      filters: {},
      pageNumber: pageNumber,
      pageSize: pageSize,
      queryConfig: { refetchInterval: 20 * 1000, refetchOnWindowFocus: true },
    },
  );
  const { data: batches, paging } = batchesResponse;
  const { data: employeesResponse, isFetching: isFetchingEmployees } =
    useEmployees({
      filters: { user: batches.map((batch) => batch.user.id).join(",") },
      pageNumber: 1,
      pageSize: 100,
      queryConfig: { refetchInterval: 20 * 1000, refetchOnWindowFocus: true },
    });
  const { data: employees } = employeesResponse;

  const showBatchDetails = (batchId) => {
    const selectedBatch = batches.find((batch) => batch.id === batchId);
    queryClient.removeQueries({ queryKey: "batch_job_data" });
    setSelectedBatchDetails({
      batchId: batchId,
      dateCreated: getLocalDateTimeWithTimeZone(selectedBatch.created_at),
      requester: employeeLookup[selectedBatch.user.id],
      action: getBatchAction(selectedBatch),
      batchSize: selectedBatch.batch_size,
      status: selectedBatch.status,
    });
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [isModalOpen]);

  const handleClose = () => {
    setSelectedBatchDetails({
      batchId: null,
      dateCreated: null,
      requester: null,
      action: null,
      batchSize: null,
      status: null,
    });
    setIsModalOpen(false);
  };

  const isFetching = isFetchingBatches || isFetchingEmployees;
  const tableHeaders = [
    { label: "Date" },
    { label: "Requester" },
    { label: "Action" },
    { label: "Status" },
  ];

  const employeeLookup = employees.reduce((acc, employee) => {
    acc[employee.user?.id] = employee.full_name;
    return acc;
  }, {});

  const tableBody = batches.map((batch) => ({
    rowId: batch.id,
    rowData: [
      { data: getLocalDateTimeWithTimeZone(batch.created_at) },
      { data: employeeLookup[batch.user.id] || null },
      { data: getBatchAction(batch) },
      {
        data: (
          <Badge
            text={
              batch.status === "dead"
                ? "Complete with errors"
                : batch.status === "successful"
                  ? "Complete"
                  : "Pending"
            }
            color={getStatusBadgeColor(batch.status)}
            className={`capitalize border ${getStatusBadgeClassname(batch.status)}`}
            style={getStatusBadgeStyle(batch.status)}
          />
        ),
      },
    ],
  }));

  return (
    <div className="py-10 px-20 h-full flex flex-grow-0 flex-col">
      <div
        data-testid="import-center-header"
        className="pb-6 flex justify-between"
      >
        <div>
          <h1 className="pt-1 pb-2 font-bold">Import Center</h1>
          <p>All scheduled bulk operations and imports appear here.</p>
        </div>
        <div className=" content-center">
          <Button
            iconLeft={<Icon icon="IconFile" color="#182A44" />}
            label="Import Users"
            className="capitalize"
            disabled
          />
        </div>
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No operations found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="import-center-table"
        onRowClick={showBatchDetails}
        showSpinnerOnEmptyFetch
      />
      <div>
        {isModalOpen && (
          <Modal
            ref={modalRef}
            title="Job Progress"
            size="jumbo"
            data-testid="user-invite-modal"
            spinnerShow={false}
            onRequestClose={handleClose}
            showBorderRadius={true}
            disableScroll={true}
            modal
          >
            <BatchDetails {...selectedBatchDetails} />
          </Modal>
        )}
      </div>
    </div>
  );
};
