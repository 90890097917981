export const flattenDataAndThrowErrors = (response) => {
  if (response.name === "Error") {
    throw response;
  }
  return response?.data?.data;
};

export const flattenResponseAndThrowErrors = (response) => {
  if (response.name === "Error") {
    throw response;
  }
  return response?.data;
};

export const defaultQueryConfig = {
  select: flattenDataAndThrowErrors,
  onError: () => alert("There was an error. Please contact support."),
  // 1 hour stale time
  staleTime: 1000 * 60 * 60,
  refetchOnWindowFocus: false,
};

export const defaultMutationConfig = {
  onError: () => alert("There was an error. Please contact support."),
};

export const defaultPagingQueryConfig = {
  select: flattenResponseAndThrowErrors,
  onError: () => alert("There was an error. Please contact support."),
  // 1 hour stale time
  staleTime: 1000 * 60 * 60,
  refetchOnWindowFocus: false,
};
