import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ progress, dataTestId = "progress-bar" }) => {
  return (
    <div
      data-testid={dataTestId}
      className="w-full h-4 flex justify-start rounded bg-white border border-dark-grey"
    >
      <div
        className="bg-brand-blue h-full rounded border border-brand-blue w-full"
        data-testid={`${dataTestId}-progress`}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  dataTestId: PropTypes.string,
};

export default ProgressBar;
