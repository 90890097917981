import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FilterSingle, Filter } from "@unite-us/client-utils";
import { fetchOrganization } from "api/fetch/fetchOrganizations";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { formatFilterOptions } from "../utils/formatFilterOptions";

const fetchOrgs = async ({ authToken, search }, dispatch) => {
  const { response: orgsResponse } = await fetchOrganization({
    authToken: authToken,
    filters: { name: search },
    page: 1,
    size: 50,
  })(dispatch);
  const orgs = orgsResponse.data.data || [];

  return orgs.map((org) => ({
    label: org.name,
    value: org.id,
  }));
};

const UsersTableFilters = ({
  filters,
  setFilters,
  filteredRoles,
  isFetchingRoles,
}) => {
  const { authToken } = useAuthContext();
  const [primaryRolesFilter, setPrimaryRolesFilter] = useState([]);
  const [featureRolesFilter, setFeatureRolesFilter] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(true);
  const [errorFetchingOrgs, setErrorFetchingOrgs] = useState(false);
  const filtersClassName = "w-1/5 pr-2 pt-1";

  const shouldUpdateRoles =
    primaryRolesFilter.length !== 0 ||
    featureRolesFilter.length !== 0 ||
    (filters && filters["roles.key"]);

  useEffect(() => {
    if (shouldUpdateRoles) {
      setFilters((prevFilters) => {
        const roleKeys = primaryRolesFilter.concat(featureRolesFilter);
        return { ...prevFilters, "roles.key": roleKeys.join(",") };
      });
    }
  }, [primaryRolesFilter, featureRolesFilter, setFilters, shouldUpdateRoles]);

  useEffect(() => {
    fetchOrgs({ authToken: authToken, search: "" })
      .then((orgs) => {
        setOrganizations(orgs);
        setIsFetchingOrgs(false);
      })
      .catch(() => {
        setErrorFetchingOrgs(true);
      });
  }, []);

  return (
    <div className="flex justify-start flex-wrap w-full pb-2 [&_button>svg]:-translate-y-1.5">
      <FilterSingle
        key="user-status-filter-key"
        uniqIdPrefx="user-status-filter"
        name="user-status"
        className={filtersClassName}
        dataTestId="user-status-filter"
        value={filters.state || ""}
        options={[
          { label: "All User Status", value: "" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ]}
        onFilterChange={(target) => {
          setFilters({ ...filters, state: target.value });
        }}
        filterKey="userStatusFilter"
      />
      <Filter
        key="primary-role-filter-key"
        id="primary-role-filter"
        name={isFetchingRoles ? "Fetching Roles..." : "Primary Role"}
        dataTestId="primary-role-filter"
        options={
          isFetchingRoles ? [] : formatFilterOptions(filteredRoles.primaryRoles)
        }
        searchPlaceholder="Primary Role"
        className={`${filtersClassName} ${isFetchingRoles ? "opacity-50 pointer-events-none" : ""}`}
        onFiltersChange={(values) => setPrimaryRolesFilter(values)}
        hideSelectAll
        filterKey="primaryRoleFilter"
      />
      <Filter
        key="feature-role-filter-key"
        id="feature-role-filter"
        name={isFetchingRoles ? "Fetching Roles..." : "Feature Role"}
        dataTestId="feature-role-filter"
        options={
          isFetchingRoles ? [] : formatFilterOptions(filteredRoles.featureRoles)
        }
        searchPlaceholder="Feature Role"
        className={`${filtersClassName} ${isFetchingRoles ? "opacity-50 pointer-events-none" : ""}`}
        onFiltersChange={(values) => setFeatureRolesFilter(values)}
        hideSelectAll
        filterKey="featureRoleFilter"
      />
      <Filter
        key="organization-filter-key"
        id="organization-filter"
        asyncLoadingText="Fetching Organizations..."
        asyncSearch={(search) =>
          fetchOrgs({ authToken: authToken, search: search })
        }
        name={
          errorFetchingOrgs
            ? "Error Fetching Orgs"
            : isFetchingOrgs
              ? "Fetching Organizations..."
              : "Organization"
        }
        pluralName="Organizations"
        filterKey="organizationsFilter"
        options={organizations}
        onFiltersChange={(target) => {
          setFilters({ ...filters, provider: target.join(",") });
        }}
        searchPlaceholder="Search for an organization"
        className={`${filtersClassName} ${isFetchingOrgs || errorFetchingOrgs ? "opacity-50 pointer-events-none" : ""}`}
        dataTestId="organization-filter"
        hideSelectAll={true}
      />
    </div>
  );
};

UsersTableFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  filteredRoles: PropTypes.object.isRequired,
  isFetchingRoles: PropTypes.bool.isRequired,
};

export default UsersTableFilters;
