import { useFindRecord } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { defaultQueryConfig } from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useFindScimConfiguration = (id) => {
  const { authToken } = useAuthContext();
  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.AUTH_API_BASE_URL,
    authToken,
  );

  return useFindRecord("scim_configuration", id, jsonApiAdapter, {
    queryConfig: {
      ...defaultQueryConfig,
      enabled: !!id,
    },
  });
};
