export const getEmployeeMatchingRoleNames = (employee, roles) => {
  const matchingRoles = [];

  employee?.roles?.forEach((role) => {
    const matchingRole = roles.find(
      (primaryRole) => primaryRole.id === role.id,
    );
    if (matchingRole) {
      matchingRoles.push(matchingRole.name);
    }
  });

  matchingRoles.sort();
  return matchingRoles.length ? matchingRoles : ["None"];
};
