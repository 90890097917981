import React from "react";
import PlanForm from "components/Forms/PlanForm";
import { useCreatePlan } from "api/core/planHooks";
import { useNavigate, useParams } from "react-router";
import { constructPlanFormPayload } from "./utils/constructPlanFormPayload";

const CreatePlan = () => {
  const navigate = useNavigate();
  const { payerId } = useParams();
  const { createRecord: createPlan } = useCreatePlan({ onError: () => {} });

  const handleSubmit = async (values) => {
    const payload = constructPlanFormPayload(values, payerId);
    try {
      await createPlan(payload);
      navigate(`/payers/${payerId}`);
    } catch (error) {
      console.error("Error creating plan: ", error);
    }
  };

  return <PlanForm onSubmit={handleSubmit} payerId={payerId} />;
};

export default CreatePlan;
