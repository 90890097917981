import { defaultPagingQueryConfig } from "api/apiUtils.js";
import { useFind } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useTags = ({ filters = {} }) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "tag",
    {
      ...filters,
    },
    jsonApiAdapter,
    {
      queryConfig: { ...defaultPagingQueryConfig },
    },
  );
};
