import React from "react";

export const MissionControlLogo = {
  viewBox: "0 0 106 31",
  markup: (
    <g>
      <path
        d="M15.2209 28.7496C15.529 28.7496 15.709 28.6426 15.9321 28.469C16.8143 27.7823 17.1781 26.822 17.1781 25.5748V6.76327C14.4134 9.96361 12.7389 14.1256 12.7389 18.6802V25.5747C12.7389 26.1228 12.6286 26.642 12.4445 27.1257C12.9909 28.0938 14.0064 28.7496 15.2209 28.7496Z"
        fill="white"
      />
      <path
        d="M19.0803 25.5745C19.0803 24.6645 19.0803 23.4066 19.0803 21.8867V7.66358C19.0803 4.38848 20.8671 1.53044 23.52 0.000129993V21.8867H23.4874C23.1108 26.4286 19.2951 30 14.6409 30C17.0929 29.9999 19.0803 28.0186 19.0803 25.5745Z"
        fill="white"
      />
      <path
        d="M6.50501 29.3098C5.26512 28.5244 4.43965 27.1473 4.43965 25.5744C4.43965 24.6643 4.43965 23.4064 4.43965 21.8866V7.66345C4.43965 4.38835 2.65292 1.53031 0 0V21.8866H0.0325817C0.356412 25.7926 3.22598 28.9731 6.98241 29.7898C6.81394 29.6389 6.65451 29.4791 6.50501 29.3098Z"
        fill="white"
      />
      <path
        d="M6.50501 29.3098C6.65451 29.4791 6.81394 29.6389 6.98241 29.7898C7.59431 29.9231 8.22717 29.9999 8.8792 29.9999C8.00496 29.9999 7.19221 29.7444 6.50501 29.3098Z"
        fill="white"
      />
      <path
        d="M10.7816 25.5749V18.6805C10.7816 14.1259 9.10628 9.96387 6.342 6.76352V25.575C6.342 28.0188 8.33028 30 10.7816 30C13.2336 30 13.6707 30 15.2209 30C12.7685 29.9999 10.7816 28.0186 10.7816 25.5749Z"
        fill="white"
      />
      <path
        d="M9.65283 7.45993L10.4575 4.98944L8.34857 3.46378H10.9557L11.7604 0.993795L12.5662 3.46378H15.1715L13.0636 4.99043L13.8689 7.45993L11.7604 5.93318L9.65283 7.45993Z"
        fill="white"
      />
      <path
        d="M32.8 12V0.207999H35.328L38.72 8.768L42.096 0.207999H44.624V12H42.624V3.76L39.28 12H38.144L34.8 3.76V12H32.8Z"
        fill="white"
      />
      <path
        d="M46.8706 12V10.176H48.0706V2.032H46.8706V0.207999H51.2706V2.032H50.0706V10.176H51.2706V12H46.8706Z"
        fill="white"
      />
      <path
        d="M53.5031 12V10.176L59.3591 10.16L60.0631 9.456L60.0471 7.472L59.3591 6.768H54.8791L53.1031 4.992V1.984L54.8791 0.207999H61.4071V2.032L55.8231 2.048L55.1031 2.736L55.1191 4.304L55.8231 5.024L60.2871 5.008L62.0631 6.784V10.224L60.2871 12H53.5031Z"
        fill="white"
      />
      <path
        d="M63.8625 12V10.176L69.7185 10.16L70.4225 9.456L70.4065 7.472L69.7185 6.768H65.2385L63.4625 4.992V1.984L65.2385 0.207999H71.7665V2.032L66.1825 2.048L65.4625 2.736L65.4785 4.304L66.1825 5.024L70.6465 5.008L72.4225 6.784V10.224L70.6465 12H63.8625Z"
        fill="white"
      />
      <path
        d="M74.2612 12V10.176H75.4613V2.032H74.2612V0.207999H78.6612V2.032H77.4613V10.176H78.6612V12H74.2612Z"
        fill="white"
      />
      <path
        d="M82.3497 12L80.5737 10.224V1.984L82.3497 0.207999H88.1257L89.9017 1.984V10.224L88.1257 12H82.3497ZM83.2937 10.24H87.1977L87.9017 9.536L87.8857 2.672L87.1977 1.968H83.2777L82.5737 2.672L82.5897 9.536L83.2937 10.24Z"
        fill="white"
      />
      <path
        d="M92.1437 12V0.207999H94.7197L99.3917 8.448V0.207999H101.408V12H99.2157L94.5437 3.76H94.1437V12H92.1437Z"
        fill="white"
      />
      <path
        d="M34.256 31L32.48 29.224V20.984L34.256 19.208H39.824L41.44 20.824L40.176 22.104L39.008 20.968H35.184L34.48 21.672L34.496 28.536L35.184 29.24H39.152L40.32 28.088L41.584 29.384L39.968 31H34.256Z"
        fill="white"
      />
      <path
        d="M44.6466 31L42.8706 29.224V20.984L44.6466 19.208H50.4226L52.1986 20.984V29.224L50.4226 31H44.6466ZM45.5906 29.24H49.4946L50.1986 28.536L50.1826 21.672L49.4946 20.968H45.5746L44.8706 21.672L44.8866 28.536L45.5906 29.24Z"
        fill="white"
      />
      <path
        d="M54.4406 31V19.208H57.0166L61.6886 27.448V19.208H63.7046V31H61.5126L56.8406 22.76H56.4406V31H54.4406Z"
        fill="white"
      />
      <path
        d="M68.5087 31V21.032H65.2287V19.208H73.7887V21.032H70.5087V31H68.5087Z"
        fill="white"
      />
      <path
        d="M75.3156 31V19.208H82.1796L83.9556 20.984V24.888L82.3396 26.504L84.3396 31H82.0676L80.2116 26.664H77.3156V31H75.3156ZM77.3156 24.904H81.2516L81.9556 24.2V21.672L81.2516 20.968H77.3156V24.904Z"
        fill="white"
      />
      <path
        d="M87.881 31L86.105 29.224V20.984L87.881 19.208H93.657L95.433 20.984V29.224L93.657 31H87.881ZM88.825 29.24H92.729L93.433 28.536L93.417 21.672L92.729 20.968H88.809L88.105 21.672L88.121 28.536L88.825 29.24Z"
        fill="white"
      />
      <path
        d="M97.675 31V19.208H99.675V29.176H105.387V31H97.675Z"
        fill="white"
      />
    </g>
  ),
};
