import { uniqWith, isEqual } from "lodash";

const getUniquePhoneNumbers = (employees) => {
  const phoneNumbers = employees.map((employee) => employee.phone_numbers);
  const uniquePhoneNumbers = uniqWith(phoneNumbers.flat(), isEqual);
  return uniquePhoneNumbers;
};

export const getEmployeesPhonesString = (employees) => {
  const phoneNumbers = getUniquePhoneNumbers(employees);
  return phoneNumbers
    .map((phone) => {
      const phoneTypeCapitalized =
        phone.phone_type.charAt(0).toUpperCase() +
        phone.phone_type.slice(1).toLowerCase();
      const areaCode = phone.phone_number.slice(0, 3);
      const middlePart = phone.phone_number.slice(3, 6);
      const lastPart = phone.phone_number.slice(6, 10);

      const formattedNumber = `${areaCode}-${middlePart}-${lastPart}`;

      return `(${phoneTypeCapitalized}) ${formattedNumber}`;
    })
    .join(", ");
};
