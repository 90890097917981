import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForms } from "api/core/formHooks";
import {
  useFormUsages,
  useCreateFormUsage,
  useUpdateFormUsage,
} from "api/core/formUsageHooks";
import { isEmpty } from "lodash";
import DialogV2 from "components/DialogV2/DialogV2";
import PaginatedTable from "components/Tables/PaginatedTable";
import SearchBar from "components/SearchBar/SearchBar";

const AddReplaceEnrollmentForm = ({
  modalRef,
  planId,
  existingEnrollmentFormId,
}) => {
  const [selectedFormId, setSelectedFormId] = useState(
    existingEnrollmentFormId,
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(1);
  const { createRecord: createFormUsage } = useCreateFormUsage();
  const { updateRecord: updateFormUsage } = useUpdateFormUsage();

  const { data: formUsageResponse } = useFormUsages({
    form_utilizer: planId,
    "form_utilizer.type": "plan",
    form: existingEnrollmentFormId,
  });
  const formUsage = formUsageResponse?.data?.[0];

  const closeModal = (previousEnrollmentFormId) => {
    modalRef.current.closeDialog();
    previousEnrollmentFormId && setSelectedFormId(previousEnrollmentFormId);
  };

  const {
    data: formsResponse,
    isFetching: isFetchingForms,
    isError: isErrorFetchingForms,
  } = useForms({ name: searchTerm }, pageSize, pageNumber);
  const { data: forms, paging } = formsResponse || {};

  const tableHeaders = [
    { label: "Name" },
    { label: "Last Updated" },
    { label: "Form ID" },
  ];

  const tableBody = forms?.map((form) => ({
    rowId: form.id,
    rowClassName: selectedFormId === form.id ? "bg-pale-grey" : "",
    rowData: [
      { data: form.name },
      {
        data: new Date(form.updated_at).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }),
      },
      { data: form.id },
    ],
  }));

  const handleSearch = (data) => {
    setPageNumber(1);
    setSearchTerm(data?.search);
  };

  const addFormHandler = async (formId, plan_id) => {
    let response;
    try {
      response = await createFormUsage({
        usage_type: "enrollment",
        form: {
          id: formId,
          type: "form",
        },
        form_utilizer: {
          id: plan_id,
          type: "plan",
        },
      });
    } catch (error) {
      console.error("Failed to add form usage: ", error);
    }
    response && closeModal();
  };

  const replaceFormHandler = async (formId, formUsageId) => {
    let response;
    try {
      response = await updateFormUsage(formUsageId, {
        form: {
          id: formId,
          type: "form",
        },
      });
    } catch (error) {
      console.error("Failed to replace form usage: ", error);
    }
    response && closeModal();
  };

  return (
    <DialogV2
      cancelHandler={() => closeModal(existingEnrollmentFormId)}
      confirmationHandler={
        isEmpty(existingEnrollmentFormId)
          ? () => addFormHandler(selectedFormId, planId)
          : () => replaceFormHandler(selectedFormId, formUsage.id)
      }
      confirmLabel="Select"
      id={"add-replace-form-modal"}
      ref={modalRef}
      title={"Add/Replace Form"}
      confirmationBtnDisabled={selectedFormId === ""}
      width="6xl"
      confirmButtonTestId="add-replace-form-modal-confirm-button"
      dialogDescriptionClass="flex flex-col h-screen overflow-hidden bg-light-border-grey"
      scrollable={false}
      dialogWrapperPadding="p-12"
    >
      <SearchBar
        className="w-full"
        inputClassName="w-full"
        onSubmit={handleSearch}
        dataTestId="forms-search-bar"
        placeholder="Enter Form Name..."
        delay={2000}
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetchingForms}
        isError={isErrorFetchingForms}
        emptyTableMessage="No Form Found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="forms-table"
        onRowClick={(formId) => setSelectedFormId(formId)}
      />
    </DialogV2>
  );
};

AddReplaceEnrollmentForm.propTypes = {
  modalRef: PropTypes.object.isRequired,
  planId: PropTypes.string.isRequired,
  existingEnrollmentFormId: PropTypes.string,
};

AddReplaceEnrollmentForm.defaultProps = {
  existingEnrollmentFormId: "",
};

export default AddReplaceEnrollmentForm;
