import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomTable from "components/Tables/CustomTable";
import { useFindPlanEnrollmentForms } from "api/core/formHooks";
import { Button, Icon } from "@unite-us/app-components";
import AddReplaceEnrollmentForm from "./AddReplaceEnrollmentForm";

const PlanEnrollments = ({ plan }) => {
  const AddReplaceFormModal = useRef(null);
  const [enrollmentTableBody, setEnrollmentTableBody] = useState([]);
  const {
    data: forms,
    isFetching,
    isError,
  } = useFindPlanEnrollmentForms(plan.id);
  const enrollmentForm = forms?.data?.data[0];

  useEffect(() => {
    setEnrollmentTableBody(generateEnrollmentTableBody(enrollmentForm));
  }, [enrollmentForm]);

  const enrollmentTableHeader = [
    { label: "Name" },
    { label: "Last Updated" },
    { label: "Form ID" },
  ];

  const generateEnrollmentTableBody = (enrollmentForm) =>
    enrollmentForm
      ? [
          {
            rowId: enrollmentForm.id,
            rowData: [
              { data: enrollmentForm.name },
              {
                data: new Date(enrollmentForm?.updated_at).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  },
                ),
              },
              { data: enrollmentForm.id },
            ],
          },
        ]
      : [];

  const openAddReplaceFormModal = () => {
    AddReplaceFormModal.current.openDialog();
  };

  return (
    <>
      <div className="border rounded border-medium-border-blue mb-10">
        <div className="flex flex-row items-center justify-between px-4 py-1 bg-medium-fill-grey">
          <h2 className="w-12/12 flex-row font-extrabold text-brand-blue">
            Enrollment Form
          </h2>
          <Button
            id="add-replace-form-button"
            iconLeft={<Icon color="text-blue" icon="Add" />}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case"
            }
            label="Add/Replace Form"
            data-testid="add-replace-form-button"
            onClick={openAddReplaceFormModal}
          />
        </div>
        <CustomTable
          className="flex flex-col"
          body={enrollmentTableBody}
          headers={enrollmentTableHeader}
          isFetching={isFetching}
          isError={isError}
          emptyTableMessage='Click "Add Form" to add form.'
          dataTestId="plan-enrollment-form-table"
        />
      </div>
      <AddReplaceEnrollmentForm
        modalRef={AddReplaceFormModal}
        planId={plan.id}
        existingEnrollmentFormId={enrollmentForm?.id}
      />
    </>
  );
};

PlanEnrollments.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default PlanEnrollments;
