import React from "react";
import PropTypes from "prop-types";

const CardRowDivider = ({ className, dataTestId }) => (
  <hr className={className} data-testid={`${dataTestId}-card-row-divider`} />
);

CardRowDivider.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

CardRowDivider.defaultProps = {
  className: "w-full border-medium-border-blue",
  dataTestId: "",
};

export default CardRowDivider;
