import moment from "moment";

export const getTodayTimestamp = () => {
  return moment().startOf("day").unix();
};

export const getLocalDateTimeWithTimeZone = (inputDateTime) => {
  if (!inputDateTime) {
    throw new Error("Invalid input: inputDateTime is required.");
  }

  const date = new Date(inputDateTime);

  if (isNaN(date.getTime())) {
    throw new Error("Invalid input: inputDateTime is not a valid date.");
  }

  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZoneName: "short",
  });

  return formatter.format(date).replace(/,\s?/, " ");
};
