import React from "react";
import { Form, Field } from "react-final-form";
import { DateField } from "@unite-us/ui";
import moment from "moment";
import { getTodayTimestamp } from "utils/date";
import { validateReceivedDate } from "../utils/validateReceivedDate";

const RevokeConsentContent = (contact) => {
  const { id, first_name, last_name } = contact;
  return (
    <div className="revoke-consent-content">
      <p className="mb-4">
        {"The following client will have their consent revoked:"}
      </p>
      <p className="font-bold">
        {`${first_name} ${last_name}
    (Client ID: ${id})`}
      </p>
      <p className="mt-10">
        {"Enter the date that the Revoke Consent request was received:"}
      </p>
      <div className="mt-6">
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <div>
                <Field
                  className="ui-interaction-type-field__revoked-date-field w-5/12"
                  id="revoked-date-field"
                  label="Request Received Date"
                  name="revoked_on"
                  required
                  validate={validateReceivedDate}
                  customErrorMessage="Request Received Date cannot be later than today"
                  initialValue={moment(new Date()).format("MM/DD/YYYY")}
                  maxDate={getTodayTimestamp()}
                >
                  {(params) => <DateField {...params} />}
                </Field>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

export default RevokeConsentContent;
