import { useState } from "react";
import PropTypes from "prop-types";
import { BarLoader } from "@unite-us/app-components";
import { useBatchJobData } from "api/core/batchJobDataHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import BatchProgress from "./BatchProgress";

const BatchDetails = ({
  batchId,
  dateCreated,
  requester,
  action,
  batchSize,
  status,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const {
    data: jobsResponse,
    isFetching,
    isError,
  } = useBatchJobData({
    filters: { batch_data_id: batchId },
    pageNumber: pageNumber,
    pageSize: pageSize,
    queryConfig: { refetchInterval: 10 * 1000 },
  });
  const { data: jobs, paging: jobsPaging } = jobsResponse;

  const finishedJobs = jobsPaging?.total_count;

  const getNonExceptionKey = (keys) => {
    return keys.find((key) => key !== "exception");
  };

  const getErrorMessage = (error) => {
    const keys = Object.keys(error);
    if (keys.length === 0) return "";
    if (keys.length > 1) return error[getNonExceptionKey(keys)];
    return error["exception"]["exception_message"];
  };

  const tableHeaders = [
    { label: "User" },
    { label: "Status" },
    { label: "Details" },
  ];

  const jobBody = jobs.map((job) => {
    const output = JSON.parse(job.output);
    const error = JSON.parse(job.error);

    return {
      rowId: job.id,
      rowData: [
        { data: output?.invitee_name },
        {
          data: output?.user_invite_response?.success
            ? "Success"
            : "Not Successful",
        },
        {
          data: getErrorMessage(error),
        },
      ],
    };
  });

  return (
    <>
      <div
        data-testid="import-center-jobs-top-header"
        className="pb-6 flex justify-between"
      >
        <div className="w-1/2">
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Date Created:</h1>
            <p className="font-bold">{dateCreated}</p>
          </div>
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Requester:</h1>
            <p className="font-bold">{requester}</p>
          </div>
          <div className="flex flex-row relative">
            <h1 className="font-bold text-left w-40">Action:</h1>
            <p className="font-bold">{action}</p>
          </div>
        </div>
        <div className="w-1/2">
          {finishedJobs !== undefined ? (
            <BatchProgress
              totalJobs={batchSize}
              finishedJobs={finishedJobs}
              status={status}
            />
          ) : (
            <div data-testid="batch-progress-loader">
              <BarLoader tall />
            </div>
          )}
        </div>
      </div>
      <PaginatedTable
        className={
          "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
        }
        body={jobBody}
        headers={tableHeaders}
        isFetching={isFetching}
        emptyTableMessage="No operations found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={jobsPaging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="import-center-jobs-table"
        isError={isError}
        showSpinnerOnEmptyFetch
      />
    </>
  );
};

BatchDetails.propTypes = {
  batchId: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequired,
  requester: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  batchSize: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default BatchDetails;
