import React, { useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@unite-us/ui";
import cx from "classnames";
import { Dialog } from "@headlessui/react";

const DialogV2 = React.forwardRef((props, ref) => {
  const {
    cancelHandler,
    cancelLabel,
    children,
    className,
    confirmLabel,
    confirmationBtnDisabled,
    confirmationHandler,
    dataTestId,
    confirmButtonTestId,
    dialogDescriptionClass,
    isWarning,
    onXClose,
    scrollable,
    title,
    vertical,
    width,
    confirmButtonIcon,
    dialogWrapperPadding,
    cancelBtnDisabled,
    capitalizeBtns,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({ openDialog, closeDialog }));

  const buttonClass = `px-5 py-2 flex border border-solid border-dark-border-blue rounded space-x-2 ${capitalizeBtns ? "capitalize" : ""}`;
  const warningClass = isWarning
    ? "border-red bg-red hover:bg-dark-red hover:border-dark-red"
    : "text-white bg-action-blue";
  const dialogPanelClass = `transform max-h-full w-full max-w-${width} flex flex-col rounded bg-white`;
  const verticalDialogPanelClass = cx(
    "absolute inset-y-0 right-0 flex flex-col justify-between",
    dialogPanelClass,
  );

  return (
    <Dialog
      className={className}
      data-testid={dataTestId}
      onClose={cancelHandler}
      open={isOpen}
    >
      <div className="fixed inset-0 overflow-hidden z-modal">
        <div
          className={`h-full ${dialogWrapperPadding} box-border flex items-center justify-center`}
        >
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-25 transition-opacity" />
          <Dialog.Panel
            className={vertical ? verticalDialogPanelClass : dialogPanelClass}
          >
            <Dialog.Title
              as="div"
              className="bg-white px-6 py-4 flex justify-between rounded"
            >
              <h3 className="text-lg font-extrabold leading-6 text-text-blue">
                {title}
              </h3>
              <Icon
                ariaLabel="cancel"
                className="text-text-blue fill-current"
                icon="IconCross"
                onClick={onXClose || cancelHandler}
                size={12}
              />
            </Dialog.Title>
            <Dialog.Description
              as="div"
              className={cx(
                "px-6 py-4 border-t border-b border-solid border-med-pale-blue",
                scrollable && "overflow-y-auto",
                dialogDescriptionClass,
              )}
            >
              {children}
            </Dialog.Description>

            <div className="px-6 py-4 flex justify-end">
              <Button
                className={cx(
                  buttonClass,
                  "border-dark-border-blue bg-white text-text-blue mr-6",
                )}
                onClick={cancelHandler}
                label={cancelLabel}
                secondary
                disabled={cancelBtnDisabled}
              />
              <Button
                className={cx(buttonClass, warningClass)}
                disabled={confirmationBtnDisabled}
                id="dialog-v2-confirm"
                data-testid={confirmButtonTestId}
                label={confirmLabel}
                onClick={confirmationHandler}
                iconLeft={confirmButtonIcon || null}
                primary
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
});

DialogV2.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  confirmLabel: PropTypes.string,
  confirmationBtnDisabled: PropTypes.bool,
  confirmationHandler: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  dialogDescriptionClass: PropTypes.string,
  isWarning: PropTypes.bool,
  onXClose: PropTypes.func,
  scrollable: PropTypes.bool,
  title: PropTypes.string,
  vertical: PropTypes.bool,
  width: PropTypes.string,
  confirmButtonIcon: PropTypes.string,
  confirmButtonTestId: PropTypes.string,
  dialogWrapperPadding: PropTypes.string,
  cancelBtnDisabled: PropTypes.bool,
  capitalizeBtns: PropTypes.bool,
};

DialogV2.defaultProps = {
  cancelLabel: "Cancel",
  className: "dialog",
  confirmLabel: "Ok",
  confirmationBtnDisabled: false,
  dataTestId: null,
  dialogDescriptionClass: "bg-light-border-grey",
  isWarning: false,
  onXClose: null,
  scrollable: true,
  title: "Confirmation",
  vertical: false,
  width: "lg",
  confirmButtonIcon: null,
  confirmButtonTestId: null,
  dialogWrapperPadding: "p-4",
  cancelBtnDisabled: false,
  capitalizeBtns: false,
};

export default DialogV2;
