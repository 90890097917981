import React from "react";
import PropTypes from "prop-types";
import { Drawer, Button } from "@unite-us/ui";
import CardRow from "components/CardRow";
import CardRowDivider from "components/CardRow/CardRowDivider";
import { usePrograms } from "api/core/programHooks";
import Spinner from "components/Spinner/Spinner";
import { isEmpty } from "lodash";
import "./UserRecordDrawer.css";

const ProgramsList = ({ programs }) => {
  const {
    data: programsResponse,
    isFetching: isFetchingPrograms,
    isError: isErrorPrograms,
  } = usePrograms({
    filters: {
      id: programs.map((program) => program.id).join(","),
    },
  });

  if (isFetchingPrograms)
    return <Spinner dataTestId="user-record-drawer-program-spinner" center />;
  if (isErrorPrograms) return <div>Error fetching programs</div>;

  return (
    <div className="flex flex-col justify-start">
      {programsResponse?.data.map((program) => (
        <div key={program.id}>{program.name}</div>
      ))}
    </div>
  );
};

const generateContentListFromArray = (content) => {
  if (!Array.isArray(content)) return;
  return (
    <div className="flex flex-col justify-start">
      {isEmpty(content)
        ? "None"
        : content.map((item) => <div key={item.id}>{item.name}</div>)}
    </div>
  );
};

const UserRecordDrawer = ({ drawerOpen, setDrawerOpen, employee }) => {
  const {
    first_name,
    last_name,
    programs,
    provider,
    roles,
    state,
    work_title,
  } = employee;

  return (
    <Drawer
      open={drawerOpen}
      secondary
      onClose={() => setDrawerOpen(false)}
      onEscClick={() => setDrawerOpen(false)}
      width="30%"
      topOffset="68.5px"
      theme="dark"
    >
      <div
        className="flex flex-col p-2"
        data-testid="user-record-drawer-content"
      >
        <div className="flex flex-row justify-between items-center">
          <h1 className="w-12/12 flex-row p-3 regular-font font-bold text-brand-blue">
            {provider?.name}
          </h1>
          <Button
            className="m-3"
            data-testid="user-record-edit-button"
            disabled
            onClick={() => {}}
            label="Edit"
          />
        </div>
        <CardRowDivider />
        <CardRow
          title={`Employee record for ${last_name}, ${first_name}`}
          divider
          dataTestId="user-record"
        />
        <CardRow
          title="Status"
          content={state}
          divider
          dataTestId="status"
          capitalize
        />
        <CardRow
          title="Work Title"
          content={work_title}
          divider
          dataTestId="work-title"
        />
        <CardRow
          title="Programs"
          content={
            programs && !isEmpty(programs) ? (
              <ProgramsList programs={programs} />
            ) : (
              "None"
            )
          }
          divider
          dataTestId="programs"
        />
        <CardRow
          title="Primary Role"
          content={roles?.primaryRoles[0].name || "None"}
          divider
          dataTestId="primary-role"
        />
        <CardRow
          title="Feature Roles"
          content={generateContentListFromArray(roles?.featureRoles)}
          divider
          dataTestId="feature-roles"
        />
      </div>
    </Drawer>
  );
};

UserRecordDrawer.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  employee: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    work_title: PropTypes.string,
    state: PropTypes.string,
    roles: PropTypes.shape({
      primaryRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
      featureRoles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    provider: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default UserRecordDrawer;
