import React from "react";

const SelectionText = ({
  isAllOrgUsersSelected,
  isAllVisibleRowsSelected,
  selectedRows,
  paging,
  hasOrgUserSelectionExpanded,
  setHasOrgUserSelectionExpanded,
}) => {
  if (isAllOrgUsersSelected) {
    return <span>{paging?.total_count} users selected</span>;
  }

  if (isAllVisibleRowsSelected) {
    return (
      <span>
        {hasOrgUserSelectionExpanded ? (
          <span>
            {paging?.total_count} users selected{" "}
            <span
              className="text-action-blue hover:cursor-pointer"
              onClick={() => setHasOrgUserSelectionExpanded(false)}
            >
              (Deselect all users)
            </span>
          </span>
        ) : (
          <span>
            {selectedRows.employees.length} users selected{" "}
            <span
              className="text-action-blue hover:cursor-pointer"
              onClick={() => setHasOrgUserSelectionExpanded(true)}
            >
              (Select all users in selected organizations)
            </span>
          </span>
        )}
      </span>
    );
  }

  return <span>{selectedRows.employees.length} users selected</span>;
};

export default SelectionText;
