import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Spinner = ({ scale, className, center, dataTestId }) => {
  const centerStyles = center
    ? ["flex", "justify-center", "h-full", "items-center"]
    : [];

  return (
    <div
      className={classNames(centerStyles, className)}
      style={{ transform: `scale(${scale})` }}
      data-testid={dataTestId}
    >
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-r-2 border-blue-500"></div>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  scale: PropTypes.number,
  center: PropTypes.bool,
  dataTestId: PropTypes.string,
};

Spinner.defaultProps = {
  className: "",
  scale: 1,
  center: false,
  dataTestId: "spinner",
};

export default Spinner;
