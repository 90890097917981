import React from "react";
import PropTypes from "prop-types";

const UserFHIRDisplay = ({ emrUsers }) => (
  <div className="flex flex-col">
    <div className="flex">
      <div className="w-2/6 pt-1 font-black text-black">
        SMART on FHIR Organization
      </div>
      <div className="w-1/6 pt-1 font-black text-black">Username</div>
      <div className="w-3/6 pt-1 font-black text-black">FHIR URL</div>
    </div>
    <hr className="col-span-4 border-medium-border-blue" />
    {emrUsers.length > 0 &&
      emrUsers.map((emrUser) => (
        <div key={emrUser.id} className="flex bg-white">
          <div
            data-testid={`organization-name-column-${emrUser.id}`}
            className="w-2/6 pt-3"
          >
            {emrUser.organization.name}
          </div>
          <div
            data-testid={`username-column-${emrUser.id}`}
            className="w-1/6 pt-3"
          >
            {emrUser.user.username}
          </div>
          <div
            data-testid={`organization-url-${emrUser.id}`}
            className="w-3/6 pt-3"
          >
            {emrUser.organization.base_url}
          </div>
        </div>
      ))}
    {emrUsers.length === 0 && <div className="w-full pt-3">None</div>}
  </div>
);

UserFHIRDisplay.propTypes = {
  emrUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      organization: PropTypes.shape({
        id: PropTypes.string,
        base_url: PropTypes.string,
        name: PropTypes.string,
      }),
      user: PropTypes.shape({
        id: PropTypes.string,
        username: PropTypes.string,
        user_id: PropTypes.string,
      }),
    }),
  ),
};

UserFHIRDisplay.defaultProps = {
  emrUsers: [],
};

export default UserFHIRDisplay;
