import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";
import {
  defaultPagingQueryConfig,
  defaultQueryConfig,
  defaultMutationConfig,
} from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const usePayers = ({
  searchTerm,
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );
  return useFind("payer", { name: searchTerm || "" }, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
    include: include || "",
  });
};

export const useFindPayer = (payerId) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );
  return useFindRecord("payer", payerId, jsonApiAdapter, defaultQueryConfig);
};

export const useCreatePayer = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("payer", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("payer");
        addToast({
          message: "Payer created successfully",
          type: "success",
          dataTestId: "create-payer-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating payer: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-payer-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};

export const useUpdatePayer = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("payer", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("payer");
        addToast({
          message: "Payer updated successfully",
          type: "success",
          dataTestId: "update-payer-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error updating payer: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "edit-payer-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};
