import React from "react";
import PropTypes from "prop-types";
import { SelectField } from "@unite-us/ui";
import { PAGE_SIZE_OPTIONS } from "./constants";

const PageSizeSelector = ({
  className,
  pageSize,
  setPageSize,
  setPageNumber,
}) => {
  const options = PAGE_SIZE_OPTIONS.map((val) => ({
    label: val.toString(),
    value: val.toString(),
  }));

  const handlePageSizeChange = (newValue) => {
    const newPageSize = parseInt(newValue, 10);
    setPageSize(newPageSize);
    setPageNumber(1);
  };

  return (
    <SelectField
      id="page-size-selector"
      className={className}
      position="top"
      shouldSort={false}
      clearable={false}
      onChange={(newValue) => handlePageSizeChange(newValue)}
      options={options}
      value={pageSize.toString()}
      searchEnabled={false}
      inline
      label={
        <span className="capitalize text-base">
          Show
          <span className="inset-0 sr-only">items per page</span>
        </span>
      }
      style={{
        container: {
          margin: "0px",
        },
        error: { height: "0px" },
      }}
    />
  );
};

PageSizeSelector.propTypes = {
  className: PropTypes.string,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
};

PageSizeSelector.defaultProps = {
  className: "",
};

export default PageSizeSelector;
