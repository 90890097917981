import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "components/auth/AuthContextProvider.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, ToastProvider } from "@unite-us/app-components";

const root = ReactDOM.createRoot(document.getElementById("root"));
const routeBasepath = window.feConfig.ROUTE_BASEPATH;
const appRootPath = routeBasepath !== "/" ? routeBasepath : "";
const authRedirectUrl = `${window.location.protocol}//${window.location.host}${appRootPath}/callback/`;
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <AuthContextProvider
      clientId={window.feConfig.CLIENT_ID}
      cookieSessionKey={window.feConfig.COOKIE_SESSION_KEY}
      authRedirectUrl={authRedirectUrl}
      authApiUrl={window.feConfig.AUTH_API_URL}
      callbackUrlCookieKey={window.feConfig.CALLBACK_URL_COOKIE_KEY}
      routeBasepath={routeBasepath}
      refreshSessionInterval={window.feConfig.REFRESH_SESSION_INTERVAL_SECONDS}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ToastProvider>
            <ToastContainer />
            <App />
          </ToastProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthContextProvider>
  </React.StrictMode>,
);
