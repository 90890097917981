import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@unite-us/ui";
import { useNavigate } from "react-router";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.jsx";

const NavigationHeader = ({
  crumbsData,
  backButtonRedirectTo,
  dataTestId,
  breadcrumbsClassName,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(backButtonRedirectTo);
  };

  return (
    <div className="flex flex-row border-b border-medium-border-blue pt-4 pb-2 px-8 space-x-6 w-full">
      <Button
        iconLeft={<Icon icon="IconChevronLeft" />}
        label={"Back"}
        onClick={handleButtonClick}
        data-testid={`${dataTestId}-back-button`}
        secondary
        disabled={!backButtonRedirectTo}
      />
      {crumbsData.length !== 0 && (
        <Breadcrumbs
          crumbsData={crumbsData}
          dataTestId={dataTestId}
          className={breadcrumbsClassName}
        />
      )}
    </div>
  );
};

NavigationHeader.propTypes = {
  crumbsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
    }).isRequired,
  ),
  backButtonRedirectTo: PropTypes.string,
  dataTestId: PropTypes.string,
  breadcrumbsClassName: PropTypes.string,
};

NavigationHeader.defaultProps = {
  crumbsData: [],
  backButtonRedirectTo: null,
  dataTestId: "navigation-header",
  breadcrumbsClassName: "",
};

export default NavigationHeader;
