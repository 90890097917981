export const constructUserInvitesPayload = (providers, roles) => {
  if (roles)
    return {
      providers: providers,
      roles: roles,
    };
  else
    return {
      providers: providers,
    };
};
