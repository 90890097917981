const ROLE_TYPES = {
  SYSTEM: "system",
  RESTRICTED: "restricted",
  PROGRAM: "program",
  PRIMARY: "primary",
  FEATURE: "feature",
};

export const filterRoles = (roles) => {
  const systemRoles = []; // legacy
  const restrictedRoles = [];
  const programRoles = []; // legacy

  const primaryRoles = [];
  const featureRoles = [];
  roles.forEach((role) => {
    if (role?.role_type === ROLE_TYPES.SYSTEM) {
      // legacy
      systemRoles.push(role);
    }
    if (role?.role_type === ROLE_TYPES.RESTRICTED) {
      restrictedRoles.push(role);
    }
    if (role?.role_type === ROLE_TYPES.PROGRAM) {
      // legacy
      programRoles.push(role);
    }
    if (role?.role_type === ROLE_TYPES.PRIMARY) {
      primaryRoles.push(role);
    }
    if (role?.role_type === ROLE_TYPES.FEATURE) {
      featureRoles.push(role);
    }
  });

  return {
    systemRoles,
    restrictedRoles,
    programRoles,
    primaryRoles,
    featureRoles,
  };
};
