import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { isNumber, range } from "lodash";
import { Icon } from "@unite-us/ui";
import TextButton from "components/TextButton/TextButton";

const PageNumberNavigationLinkSpacer = () => (
  <div
    className="-mt-px px-4 pt-4 border-t-2 border-solid border-transparent
    text-center text-grey font-heavy-font"
  >
    ...
  </div>
);

const PageNumberNavigationLink = ({
  number,
  pageNumber,
  setPageNumber,
  scrollPaginationElement,
}) => {
  const isCurrent = number === pageNumber;
  return (
    <TextButton
      ariaCurrent={isCurrent ? "page" : ""}
      ariaLabel={`Go to page ${number}`}
      dataTestId={`link-to-page-${number}`}
      className={cx(
        "-mt-px px-4 pt-4 block text-center font-heavy-font",
        "border-t-2 border-solid",
        isCurrent &&
          `
          text-current-med-blue border-current-med-blue
          hover:text-current-med-blue hover:border-current-med-blue
          focus:text-current-med-blue focus:border-current-med-blue
        `,
      )}
      onClick={(values) => {
        setPageNumber(number);
        if (scrollPaginationElement) {
          scrollPaginationElement(values.currentTarget);
        }
      }}
      role="link"
    >
      {number}
    </TextButton>
  );
};

PageNumberNavigationLink.propTypes = {
  number: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  scrollPaginationElement: PropTypes.func,
};

PageNumberNavigationLink.defaultProps = {
  scrollPaginationElement: null,
};

const PageNavigationLink = (props) => (
  <TextButton
    className="flex items-center space-x-3 -mt-px pt-4 pr-1 border-t-2 border-solid border-transparent"
    role="link"
    dataTestId={props["data-testid"]}
    {...props}
  />
);

PageNavigationLink.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

PageNavigationLink.defaultProps = {
  disabled: false,
};

const PageSelection = ({
  className,
  pageNumber,
  setPageNumber,
  totalPageCount,
  scrollPaginationElement,
  showLabels,
}) => {
  const nearStart = pageNumber - 3 <= 0;
  const nearEnd = pageNumber + 3 > totalPageCount;
  let pageOptions;
  if (totalPageCount < 7) {
    pageOptions = range(1, totalPageCount + 1);
  } else if (nearStart || nearEnd) {
    pageOptions = [
      1,
      2,
      3,
      null,
      totalPageCount - 2,
      totalPageCount - 1,
      totalPageCount,
    ];
  } else {
    pageOptions = [
      1,
      null,
      pageNumber - 1,
      pageNumber,
      pageNumber + 1,
      null,
      totalPageCount,
    ];
  }
  const isPreviousDisabled = pageNumber === 1;
  const isNextDisabled = pageNumber === totalPageCount;
  return (
    <div
      className={cx(
        "flex justify-between items-center border-t border-solid border-dark-border-blue",
        className,
      )}
    >
      <PageNavigationLink
        label="Go to previous page"
        data-testid="link-to-previous-page"
        disabled={isPreviousDisabled}
        onClick={(values) => {
          setPageNumber(pageNumber - 1);
          if (scrollPaginationElement) {
            scrollPaginationElement(values.currentTarget);
          }
        }}
      >
        <Icon className="text-action-blue fill-current" icon="IconArrowLeft" />
        {showLabels && (
          <span className="text-action-blue font-heavy-font">Previous</span>
        )}
      </PageNavigationLink>
      <ol className={cx("flex p-0")}>
        {pageOptions.map((number, index) => (
          <li key={number || `ellipsis-${index}`}>
            {isNumber(number) ? (
              <PageNumberNavigationLink
                number={number}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                scrollPaginationElement={scrollPaginationElement}
              />
            ) : (
              <PageNumberNavigationLinkSpacer />
            )}
          </li>
        ))}
      </ol>
      <PageNavigationLink
        label="Go to next page"
        data-testid="link-to-next-page"
        disabled={isNextDisabled}
        onClick={(values) => {
          setPageNumber(pageNumber + 1);
          if (scrollPaginationElement) {
            scrollPaginationElement(values.currentTarget);
          }
        }}
      >
        {showLabels && (
          <span className="text-action-blue font-heavy-font">Next</span>
        )}
        <Icon className="text-action-blue fill-current" icon="IconArrowRight" />
      </PageNavigationLink>
    </div>
  );
};

PageSelection.propTypes = {
  className: PropTypes.string,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  scrollPaginationElement: PropTypes.func,
  showLabels: PropTypes.bool,
};

PageSelection.defaultProps = {
  className: "",
  scrollPaginationElement: null,
  showLabels: true,
};

export default PageSelection;
