import {
  useFindRecordBase,
  useQueryBase,
  useFindAllBase,
  useCreateRecordBase,
  useUpdateRecordBase,
  useDeleteRecordBase,
  usePopulateBase,
  useSetRelationshipBase,
  useDeleteRelationshipBase,
  useGetBase,
  usePostBase,
} from "@unite-us/json-api-resources";
import {
  useQuery as useQueryRQ,
  useMutation,
  useQueryClient,
} from "react-query";

export const useFindRecord = (modelName, id, jsonApiAdapter, options = {}) => {
  const { queryConfig = {}, ...rest } = options;
  return useFindRecordBase(modelName, id, {
    ...rest,
    api: jsonApiAdapter,
    useQuery: useQueryRQ,
    queryConfig: { enabled: true, ...queryConfig },
  });
};

export const useFind = (modelName, query, jsonApiAdapter, options = {}) => {
  const { queryConfig = {}, ...rest } = options;
  return useQueryBase(modelName, query, {
    ...rest,
    api: jsonApiAdapter,
    useQuery: useQueryRQ,
    queryConfig: { enabled: true, ...queryConfig },
  });
};

export const useFindAll = (modelName, jsonApiAdapter, options = {}) => {
  const { queryConfig = {}, ...rest } = options;
  return useFindAllBase(modelName, {
    ...rest,
    api: jsonApiAdapter,
    useQuery: useQueryRQ,
    queryConfig: { enabled: true, ...queryConfig },
  });
};

export const useCreateRecord = (modelName, jsonApiAdapter, options = {}) => {
  const callback = useCreateRecordBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.createRecord = (data, overrideOptions = {}) => {
    const api = jsonApiAdapter;
    const newData = {
      model: modelName,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useUpdateRecord = (modelName, jsonApiAdapter, options = {}) => {
  const callback = useUpdateRecordBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.updateRecord = (id, data, overrideOptions = {}) => {
    const api = jsonApiAdapter;
    const newData = {
      model: modelName,
      id,
      data,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useDeleteRecord = (modelName, jsonApiAdapter, options = {}) => {
  const callback = useDeleteRecordBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRecord = (id, overrideOptions) => {
    const api = jsonApiAdapter;
    const newData = {
      model: modelName,
      id,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const usePopulate = (
  relationshipName,
  modelName,
  data,
  jsonApiAdapter,
  options = {},
) => {
  const { queryConfig = {}, ...rest } = options;

  return usePopulateBase(relationshipName, modelName, data, {
    ...rest,
    api: jsonApiAdapter,
    useQuery: useQueryRQ,
    queryConfig: { enabled: true, ...queryConfig },
  });
};

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  return (modelName) => queryClient.invalidateQueries(modelName);
};

export const useSetRelationship = (
  modelName,
  relationshipName,
  jsonApiAdapter,
  options = {},
) => {
  const callback = useSetRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.setRelationship = (
    modelId,
    relationshipIds,
    overrideOptions = {},
  ) => {
    const api = jsonApiAdapter;
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useDeleteRelationship = (
  modelName,
  relationshipName,
  jsonApiAdapter,
  options = {},
) => {
  const callback = useDeleteRelationshipBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.deleteRelationship = (
    modelId,
    relationshipIds,
    overrideOptions = {},
  ) => {
    const api = jsonApiAdapter;
    const newData = {
      modelName,
      id: modelId,
      relationshipModel: relationshipName,
      value: relationshipIds,
      params: { ...overrideOptions.jsonApiParams, api },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const useGet = (url, query, api, options = {}) => {
  const { queryConfig = {}, ...rest } = options;
  return useGetBase(url, query, {
    api,
    useQuery: useQueryRQ,
    queryConfig: { enabled: true, ...queryConfig },
    ...rest,
  });
};

const useRequestWithMutation = (url, api, options = {}) => {
  const callback = usePostBase({
    useMutation,
    mutationConfig: options.mutationConfig,
  });

  const mutateAsync = callback.mutateAsync;
  callback.mutate = null;
  callback.mutateAsync = null;
  callback.doExecute = (data, overrideOptions = {}) => {
    const newData = {
      url,
      data,
      params: { method: "POST", api, ...overrideOptions },
    };

    return mutateAsync(newData, overrideOptions.mutationConfig);
  };

  return callback;
};

export const usePost = (url, api, options = {}) => {
  const callback = useRequestWithMutation(url, api, options);

  callback.postFn = callback.doExecute;
  callback.doExecute = null;

  return callback;
};

export const usePatch = (url, api, options = {}) => {
  const callback = useRequestWithMutation(url, api, options);

  const doExecute = callback.doExecute;
  callback.patchFn = (data, overrideOptions = {}) =>
    doExecute(data, {
      ...overrideOptions,
      method: "PATCH",
    });
  callback.execute = null;

  return callback;
};

export const useDelete = (url, api, options = {}) => {
  const callback = useRequestWithMutation(url, api, options);

  const doExecute = callback.doExecute;
  callback.deleteFn = (data, overrideOptions = {}) =>
    doExecute(data, {
      ...overrideOptions,
      method: "DELETE",
    });
  callback.doExecute = null;

  return callback;
};

export const usePut = (url, api, options = {}) => {
  const callback = useRequestWithMutation(url, api, options);

  const doExecute = callback.doExecute;
  callback.putFn = (data, overrideOptions = {}) =>
    doExecute(data, {
      ...overrideOptions,
      method: "PUT",
    });
  callback.doExecute = null;

  return callback;
};
