import React, { useRef } from "react";
import PropTypes from "prop-types";
import CustomTable from "components/Tables/CustomTable";
import parse from "html-react-parser";
import { Button, Icon } from "@unite-us/app-components";
import { useUpdatePlan } from "api/core/planHooks";
import { generateUUID } from "utils/generateUUID";
import EditMetafields from "components/Metafields/EditMetafields";

const PlanAttachments = ({ plan }) => {
  const { updateRecord: updatePlan } = useUpdatePlan();
  const attachments = plan.metafields
    ? plan.metafields.filter((metafield) => metafield?.type === "file")
    : [];
  const editAttachmentsModal = useRef(null);

  const attachmentTableHeaders = [
    { label: "Name" },
    { label: "Requirement" },
    { label: "Description" },
  ];

  const attachmentTableBody = attachments.map((attachment, index) => ({
    rowId: `attachment-${index}`,
    rowData: [
      { data: attachment.label },
      {
        data:
          attachment.validations && attachment.validations.required
            ? "Required"
            : "Optional",
      },
      { data: attachment.help_text ? parse(attachment.help_text) : "" },
    ],
  }));

  const openEditAttachmentsModal = () => {
    editAttachmentsModal.current.openDialog();
  };

  const editAttachmentConfirmationHandler = async (newMetafields) => {
    const metafieldsPayload = newMetafields.map((metafield) => ({
      ...metafield,
      field: metafield.field || generateUUID(metafield.label),
    }));

    let response;
    try {
      response = await updatePlan(plan.id, {
        ...plan,
        metafields: metafieldsPayload,
      });
    } catch (error) {
      console.error("Error updating plan attachments", error);
    }
    response && editAttachmentsModal.current.closeDialog();
  };

  return (
    <div className="border rounded border-medium-border-blue">
      <div className="flex flex-row items-center justify-between px-4 py-1 bg-medium-fill-grey">
        <h2 className="w-12/12 flex-row py-2 font-extrabold text-brand-blue">
          Attachments
        </h2>
        <Button
          id="edit-attachment-button"
          iconLeft={<Icon color="text-blue" icon="Add" />}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case"
          }
          label="Edit Attachments"
          data-testid="edit-attachment-button"
          onClick={openEditAttachmentsModal}
        />
      </div>
      <CustomTable
        className="flex flex-col"
        body={attachmentTableBody}
        headers={attachmentTableHeaders}
        isFetching={false}
        emptyTableMessage='Click "Edit Attachments" to add attachments'
        dataTestId="plan-attachments-table"
      />
      <EditMetafields
        metafields={attachments}
        modalRef={editAttachmentsModal}
        confirmationHandler={editAttachmentConfirmationHandler}
        isAttachment
      />
    </div>
  );
};

PlanAttachments.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    metafields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        help_text: PropTypes.string,
        validations: PropTypes.shape({
          required: PropTypes.bool,
        }),
      }),
    ),
  }).isRequired,
};

export default PlanAttachments;
