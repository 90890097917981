export const trimAndNormalizeSpaces = (str) => {
  return str.trim().replace(/\s\s+/g, " ");
};

export const getCommaSeparatedListLength = (arrayString) => {
  if (!arrayString) return 0;
  return arrayString
    .split(",")
    .map((item) => item.trim())
    .filter(Boolean).length;
};
