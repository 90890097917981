import React from "react";
import PayerForm from "components/Forms/PayerForm";
import { useCreatePayer } from "api/core/payerHooks";
import { useNavigate } from "react-router";

const CreatePayer = () => {
  const navigate = useNavigate();
  const { createRecord: createPayer } = useCreatePayer();

  const handleSubmit = async (values) => {
    try {
      await createPayer(values);
      navigate("/payers");
    } catch (error) {
      console.error("Error creating payer: ", error);
    }
  };
  return <PayerForm onSubmit={handleSubmit} />;
};

export default CreatePayer;
