import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BaseCard, BaseCardHeader, BaseCardBody } from "@unite-us/ui";
import { getEmployeesAddresses } from "../utils/getEmployeesAddresses";
import { getEmployeesPhonesString } from "../utils/getEmployeesPhonesString";
import CardRow from "components/CardRow";
import CardRowDivider from "components/CardRow/CardRowDivider";
import { useTags } from "api/core/tagHooks";
import { useFindSamlConfiguration } from "api/auth/samlConfigurationHooks";
import { useFindScimConfiguration } from "api/auth/scimConfigurationHooks";
import { useFindEmrUser } from "api/auth/authEmrUserHooks";
import Spinner from "components/Spinner/Spinner";
import { UserSSODisplay } from "./UserSSODisplay";
import UserFHIRDisplay from "./UserFHIRDisplay";

const AddressCard = ({ address }) => (
  <div className="sm:min-w-56 mr-2 pl-2 border rounded border-medium-border-blue">
    <div>
      {address.line_1} #{address.line_2}
    </div>
    <div>
      {address.city}, {address.state} {address.zip}
    </div>
  </div>
);

export const UserAccountDetails = ({
  user,
  employeeRecords,
  currentEmployeeRecord,
}) => {
  const addresses = getEmployeesAddresses(employeeRecords);

  const { data: tagsData, isFetching: isFetchingTags } = useTags({
    filters: {
      employee: currentEmployeeRecord?.id,
    },
  });

  const { data: samlConfiguration, isFetching: isFetchingSaml } =
    useFindSamlConfiguration(user?.group?.id);

  const { data: scimConfiguration, isFetching: isFetchingScim } =
    useFindScimConfiguration(user?.scim_group?.id);

  const { data: emrUsersResponse, isFetching: isFetchingEmrUser } =
    useFindEmrUser(user.id);

  const isFetching =
    isFetchingTags || isFetchingSaml || isFetchingScim || isFetchingEmrUser;

  const tags = tagsData?.data?.map((tag) => tag.name).join(", ");
  return isFetching ? (
    <Spinner center />
  ) : (
    <BaseCard>
      <BaseCardHeader
        noBorder
        title="User Information"
        className="font-bold pt-3"
      />
      <BaseCardBody withPadding className="flex flex-col bg-white">
        <CardRowDivider />
        <CardRow
          title="First Name"
          content={currentEmployeeRecord?.first_name || "N/A"}
          capitalize
        />
        <CardRow
          title="Last Name"
          content={currentEmployeeRecord?.last_name || "N/A"}
          capitalize
        />
        <CardRow title="Email" content={user.email || "N/A"} />
        <CardRow
          title="Phone"
          content={getEmployeesPhonesString(employeeRecords) || "N/A"}
        />
        <CardRow
          title="Addresses"
          content={
            addresses.length > 0 ? (
              <div className="flex flex-wrap">
                {addresses.map((address) => (
                  <AddressCard
                    address={address}
                    key={address.line_1.concat(address.line_2)}
                  />
                ))}
              </div>
            ) : (
              "N/A"
            )
          }
        />
        <CardRow
          title="User has logged in?"
          content={user.last_sign_in_at ? "Yes" : "No"}
        />
        <CardRow
          title="SMART on FHIR Setup"
          content={<UserFHIRDisplay emrUsers={emrUsersResponse?.data?.data} />}
        />
        <CardRow title="Tags" capitalize content={tags} />
        <CardRow
          title="SSO"
          divider={false}
          className="pt-4"
          content={
            <UserSSODisplay
              samlName={samlConfiguration.uuid}
              scimName={scimConfiguration.name}
              provisionedOn={
                user.provisioned_at
                  ? moment
                      .utc(user.provisioned_at)
                      .format("YYYY-MM-DD HH:mm:ss ZZ")
                  : ""
              }
              provisionUpdatedOn={
                user.provision_updated_at
                  ? moment
                      .utc(user.provision_updated_at)
                      .format("YYYY-MM-DD HH:mm:ss ZZ")
                  : ""
              }
            />
          }
        />
      </BaseCardBody>
    </BaseCard>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object.isRequired,
};

UserAccountDetails.propTypes = {
  user: PropTypes.object.isRequired,
  employeeRecords: PropTypes.array.isRequired,
  currentEmployeeRecord: PropTypes.object.isRequired,
};
