import React from "react";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { useFindEmployee } from "api/core/employeeHooks";
import Spinner from "components/Spinner/Spinner";
import { UserAccountDetails } from "./components/UserAccountDetails";
import { UserRecords } from "./components/UserRecords";
import { useEmployees } from "api/core/employeeHooks";
import { useFindAuthUser } from "api/auth/authUserHooks";
import { Badge } from "@unite-us/app-components";
import { getLocalDateTimeWithTimeZone } from "utils/date";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";

const ErrorMessage = () => {
  const navigate = useNavigate();
  return (
    <div id="user-account-error" className="text-center">
      <div className="text-center">Error Fetching User</div>
      <span
        className="text-action-blue cursor-pointer text-base"
        onClick={() => navigate("/users")}
      >
        Go Back to Users Page
      </span>
    </div>
  );
};

const UserDetails = ({ employee }) => {
  const {
    data: user,
    isFetching: isFetchingUser,
    isError: isErrorUser,
  } = useFindAuthUser(employee.user?.id);
  const {
    data: employeeRecordsResponse,
    isFetching: isFetchingEmployeeRecords,
    isError: isErrorEmployeeRecords,
  } = useEmployees({
    filters: { user: employee.user?.id },
  });
  const { data: employeeRecords } = employeeRecordsResponse;
  const crumbs = [
    { label: "Users", href: "/users" },
    { label: employee?.full_name },
  ];

  const isFetching = isFetchingUser || isFetchingEmployeeRecords;
  const isError = isErrorUser || isErrorEmployeeRecords;

  return (
    <>
      {isFetching && <Spinner dataTestId="user-details-spinner" center />}
      {!isFetching && isError && <ErrorMessage />}
      {!isFetching && !isError && (
        <>
          <div id="user-account-header" className="pb-4">
            <Breadcrumbs crumbsData={crumbs} />
            <div className="inline-block my-2">
              <span
                className="w-12/12 py-2 font-extrabold text-brand-blue text-xl"
                data-testid="user-full-name"
              >
                {employee?.full_name}
              </span>
              <Badge
                text={
                  user.confirmed_at ? "Status Confirmed" : "Status Unconfirmed"
                }
                icon={{
                  position: "left",
                  name: user.confirmed_at ? "CheckCircle" : "QuestionCircle",
                  color: "white",
                  className: "mb-1",
                }}
                className="mx-4 h-8 rounded flex-row items-center justify-center"
                color={user.confirmed_at ? "#078B00" : "#DA6625"}
                shape="square"
                style={{ fontSize: "1rem", lineHeight: "1.5rem" }}
                onClick={() => {}}
              />

              {!user?.confirmed_at && (
                <div className="inline-block">
                  <span style={{ fontWeight: 900 }} className="text-lg">
                    Last Invite Sent:
                  </span>
                  <span
                    className="text-base font-normal mx-1"
                    data-testid="last-invite-sent"
                  >
                    {user?.confirmation_sent_at
                      ? getLocalDateTimeWithTimeZone(user.confirmation_sent_at)
                      : "N/A"}
                  </span>
                </div>
              )}

              {!user?.confirmed_at && user?.confirmation_sent_count > 0 && (
                <span
                  className="text-base font-normal"
                  data-testid="invitations-sent-count"
                >
                  ({user.confirmation_sent_count} attempt
                  {user.confirmation_sent_count > 1 && "s"} made)
                </span>
              )}
            </div>
          </div>
          <UserAccountDetails
            user={user}
            currentEmployeeRecord={employee}
            employeeRecords={employeeRecords}
          />
          <UserRecords user={user} />
        </>
      )}
    </>
  );
};

UserDetails.propTypes = {
  employee: PropTypes.object.isRequired,
};

export const UserAccount = () => {
  const employeeId = useParams().employeeId;
  const {
    data: employee,
    isFetching: isFetchingEmployee,
    isError: isErrorEmployee,
  } = useFindEmployee(employeeId);

  return (
    <div className="py-10 px-20">
      {isFetchingEmployee && (
        <Spinner dataTestId="user-account-spinner" center />
      )}
      {!isFetchingEmployee && !isErrorEmployee && (
        <UserDetails employee={employee} />
      )}
      {!isFetchingEmployee && isErrorEmployee && <ErrorMessage />}
    </div>
  );
};
