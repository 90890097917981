import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Icon, InputField } from "@unite-us/ui";
import { Form, Field } from "react-final-form";

const SearchBar = ({
  className,
  inputClassName,
  placeholder,
  onSubmit,
  dataTestId,
  autoSubmit,
  delay,
  minAutoSearchLength,
}) => {
  const [value, setValue] = useState("");
  const timerRef = useRef(null);

  useEffect(() => {
    if (autoSubmit && delay > 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => onSubmit({ search: value }), delay);
    }

    return () => clearTimeout(timerRef.current);
  }, [value, autoSubmit, delay, onSubmit]);

  return (
    <div className={className}>
      <Icon
        className="absolute pt-2 pl-3"
        icon="IconSearch"
        size={30}
        color={"#2C405A"}
      />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="search">
              {(props) => (
                <InputField
                  className={inputClassName}
                  hideError
                  style={{ input: { paddingLeft: "2.5rem" } }}
                  placeholder={placeholder}
                  label="search"
                  id="search"
                  hideLabel
                  dataTestId={dataTestId}
                  {...props}
                  value={value}
                  onChange={(event) => {
                    setValue(event.target.value);
                    if (
                      !delay &&
                      autoSubmit &&
                      (event.target.value === "" ||
                        event.target.value.length >= minAutoSearchLength)
                    ) {
                      handleSubmit();
                    }
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSubmit();
                    }
                  }}
                />
              )}
            </Field>
          </form>
        )}
      />
    </div>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
  autoSubmit: PropTypes.bool,
  delay: PropTypes.number,
  minAutoSearchLength: PropTypes.number,
};

SearchBar.defaultProps = {
  className: "flex-grow",
  inputClassName: "md:w-4/5",
  placeholder: "Search by Keyword",
  dataTestId: "",
  autoSubmit: true,
  delay: 0,
  minAutoSearchLength: 2,
};

export default SearchBar;
