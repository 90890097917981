const statusColors = {
  retrying: "#FFD7C1",
  complete: "#FFD7C1",
  successful: "#D9F8D7",
  processing: "#FFD7C1",
  dead: "#FF9494",
};

const statusTextColors = {
  complete: "#DA6625",
  retrying: "#DA6625",
  successful: "#078B00",
  processing: "#DA6625",
  dead: "#991B1B",
};

export const getStatusBadgeColor = (status) => {
  return statusColors[status.toLowerCase()];
};

export const getStatusBadgeClassname = (status) => {
  switch (status.toLowerCase()) {
    case "complete":
    case "processing":
    case "retrying":
      return "border-orange";
    case "successful":
      return "border-dark-green";
    case "dead":
      return "border-dark-red";
    default:
      return "bg-gray-100";
  }
};

export const getStatusBadgeStyle = (status) => {
  return {
    color: statusTextColors[status.toLowerCase()],
  };
};
