import axios from "axios";
import {
  buildSerializer,
  buildJSONAPIDeserializerInterceptor,
  JSONAPIAdapter,
} from "@unite-us/json-api-resources";

const getCoreApi = (authToken) => {
  const CORE_API_BASE_URL = window.feConfig.CORE_API_BASE_URL;
  const serializer = buildSerializer();
  const JSONAPIDeserializerInterceptor =
    buildJSONAPIDeserializerInterceptor(serializer);

  const apiDefault = axios.create({
    baseURL: CORE_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });

  apiDefault.interceptors.response.use(JSONAPIDeserializerInterceptor);

  const coreApi = new JSONAPIAdapter(apiDefault, serializer);

  return coreApi;
};

export { getCoreApi };
