import moment from "moment";

export const constructPlanInitialFormValues = (plan, networks) => {
  const initialValues = { ...plan };

  if (initialValues && initialValues.starts_at) {
    initialValues.starts_at = moment(
      initialValues.starts_at,
      "YYYY-MM-DD",
    ).format("MM/DD/YYYY");
  }
  if (initialValues && initialValues.ends_at) {
    initialValues.ends_at = moment(initialValues.ends_at, "YYYY-MM-DD").format(
      "MM/DD/YYYY",
    );
  }
  if (initialValues && initialValues.enrollment_required !== undefined) {
    initialValues.enrollment_required =
      initialValues.enrollment_required.toString();
  }
  if (networks && networks.length) {
    initialValues.networks = networks.map((network) => ({
      id: network.id,
      name: network.name,
    }));
  }
  return initialValues;
};
