export const toggleSelectAllRows = (
  employees,
  setSelectedRows,
  isAllVisibleRowsSelected,
) => {
  if (isAllVisibleRowsSelected) {
    setSelectedRows({ employees: [], users: [] });
  } else {
    setSelectedRows({
      employees: employees.map((employee) => employee.id),
      users: employees.map((employee) => employee.user.id),
    });
  }
};
