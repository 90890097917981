import DialogV2 from "components/DialogV2/DialogV2";
import { forwardRef } from "react";

const InviteDialog = forwardRef(
  (
    { usersCount, isLoadingInvite, cancelInviteHandler, confirmInviteHandler },
    ref,
  ) => (
    <DialogV2
      ref={ref}
      title={`Invite Users (${usersCount})`}
      confirmLabel="Send"
      confirmationBtnDisabled={isLoadingInvite}
      cancelBtnDisabled={isLoadingInvite}
      cancelLabel="Cancel"
      cancelHandler={cancelInviteHandler}
      confirmationHandler={confirmInviteHandler}
      dataTestId="invite-users-dialog"
      onXClose={isLoadingInvite ? () => {} : cancelInviteHandler}
      capitalizeBtns
    >
      <div>
        <div>
          <p>
            Invites will be sent to active users who meet the following
            criteria:
          </p>
          <ul className="list-disc list-inside py-4 text-sm">
            <li>Have an unconfirmed account</li>
            <li>
              Associated with an organization that is connected to a network
            </li>
          </ul>
          <p>Select send to start the operation request.</p>
        </div>
      </div>
    </DialogV2>
  ),
);

export default InviteDialog;
