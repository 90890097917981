import React from "react";
import { Button, Icon } from "@unite-us/app-components";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router";
import { usePlans } from "api/core/planHooks";
import { setPlanTabLocation } from "utils/localStorage";
import CustomTable from "components/Tables/CustomTable";

const PlansTable = ({ planIds }) => {
  const navigate = useNavigate();
  const { payerId } = useParams();
  const {
    data: plansResponse,
    isFetching,
    isError,
  } = usePlans({
    filters: { id: planIds.length > 0 ? planIds.join(",") : "" },
  });
  const plans = plansResponse?.data || [];

  const plansTableHeader = [
    { label: "Name" },
    { label: "Type" },
    { label: "External ID" },
  ];
  const plansTableBody = plans.map((plan) => ({
    rowId: plan.id,
    rowData: [
      { data: plan.name },
      { data: plan.plan_type },
      { data: plan.external_id },
    ],
  }));

  const rowClickHandler = (planId) => {
    setPlanTabLocation("overview");
    navigate(`/payers/${payerId}/plans/${planId}`);
  };

  return (
    <div className="border rounded border-medium-border-blue">
      <div className="flex flex-row items-center justify-between px-4 py-1 bg-medium-fill-grey">
        <h2 className="w-12/12 flex-row py-2 font-extrabold text-brand-blue">
          Associated Plans
        </h2>
        <Button
          id="add-plan-button"
          iconLeft={<Icon color="text-blue" icon="Add" />}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case"
          }
          label="Add Plan"
          data-testid="add-plan-btn"
          onClick={() => {
            navigate(`/payers/${payerId}/plans/new`);
          }}
        />
      </div>
      <CustomTable
        headers={plansTableHeader}
        body={plansTableBody}
        isFetching={isFetching}
        isError={isError}
        dataTestId="plans-table"
        errorMessage="Error Fetching Plans"
        emptyTableMessage="No Plans Found"
        onRowClick={rowClickHandler}
        className="h-fit"
      />
    </div>
  );
};

PlansTable.propTypes = {
  planIds: PropTypes.array.isRequired,
};

export default PlansTable;
