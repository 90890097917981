import { defaultPagingQueryConfig } from "api/apiUtils.js";
import { useFind } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const usePrograms = ({
  filters = {},
  include = "",
  pageSize = 25,
  pageNumber = 1,
  sort = "",
}) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "program",
    {
      ...filters,
    },
    jsonApiAdapter,
    {
      queryConfig: { ...defaultPagingQueryConfig },
      page: { number: pageNumber, size: pageSize },
      sort: sort,
      include: include,
    },
  );
};
