import moment from "moment";

export const validateReceivedDate = (value) => {
  if (!value) {
    return "Request Received Date is required";
  }

  const selectedDate = moment.unix(value);

  if (!selectedDate.isValid()) {
    return "Invalid date format";
  }

  const today = moment().startOf("day");

  if (selectedDate.isAfter(today)) {
    return "Request Received Date cannot be later than today";
  }

  return undefined;
};
