import React from "react";

export const consentStatuses = {
  accepted: (
    <span>
      <span className="bg-green inline-block w-3 h-3 rounded-full mr-2" />
      Accepted
    </span>
  ),
  "needs-consent": (
    <span>
      <span className="bg-yellow inline-block w-3 h-3 rounded-full mr-2" />
      Needs Consent
    </span>
  ),
  revoked: (
    <span>
      <span className="bg-red inline-block w-3 h-3 rounded-full mr-2" />
      Revoked
    </span>
  ),
  declined: (
    <span>
      <span className="bg-blue-700 inline-block w-3 h-3 rounded-full mr-2" />
      Declined
    </span>
  ),
};
