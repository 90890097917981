import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useFindPayer, useUpdatePayer } from "api/core/payerHooks";
import { useToast } from "@unite-us/app-components";
import PayerForm from "components/Forms/PayerForm";
import Spinner from "components/Spinner/Spinner";

const EditPayer = () => {
  const navigate = useNavigate();
  const payerId = useParams().payerId;
  const { addToast } = useToast();
  const {
    data: payerResponse,
    isFetching,
    isError: isErrorFetching,
    error: fetchingError,
  } = useFindPayer(payerId);
  const payer = payerResponse?.data?.data || {};
  const { updateRecord: updatePayer } = useUpdatePayer();

  const handleSubmit = async (values) => {
    try {
      await updatePayer(payerId, values);
      navigate(`/payers/${payerId}`);
    } catch (error) {
      console.error("Error updating payer: ", error);
    }
  };

  useEffect(() => {
    if (isErrorFetching) {
      addToast({
        message: `Error fetching payer data: ${fetchingError || "Unknown error"}`,
        type: "error",
        dataTestId: "loading-edit-payer-error-toast",
      });
      navigate(`/payers/${payerId}`);
    }
  }, [isErrorFetching, fetchingError, navigate, addToast, payerId]);

  return (
    <>
      {isFetching ? (
        <Spinner className="h-screen" center dataTestId="edit-payer-spinner" />
      ) : (
        <PayerForm
          onSubmit={handleSubmit}
          initialValues={payer}
          payerId={payerId}
        />
      )}
    </>
  );
};

export default EditPayer;
