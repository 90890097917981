import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { DefaultLayout } from "layouts/DefautLayout.js";
import { OAuthLogin } from "components/auth/OAuthLogin.js";
import { RevokeConsent } from "pages/revoke-consent/RevokeConsent.jsx";
import { Payers } from "pages/payers/Payers.jsx";
import Users from "pages/users/Users.jsx";
import CreatePayer from "pages/payers/CreatePayer.jsx";
import Payer from "pages/payers/_id/Payer.jsx";
import EditPayer from "pages/payers/_id/EditPayer.jsx";
import CreatePlan from "pages/payers/_id/plans/CreatePlan.jsx";
import Plan from "./payers/_id/plans/_id/Plan.jsx";
import EditPlan from "pages/payers/_id/plans/_id/EditPlan.jsx";
import { ImportCenter } from "pages/import-center/ImportCenter.jsx";
import { UserAccount } from "pages/users/_id/UserAccount.jsx";
import { Navigate } from "react-router-dom";

// use this for the future home/landing page
// import { Home } from "pages/home/Home.js";

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<DefaultLayout />}>
      <Route index element={<Navigate to="/users" replace={true} />} />
      <Route path="callback" element={<OAuthLogin />} />
      <Route path="revoke-consent" element={<RevokeConsent />} />
      <Route path="users" element={<Users />} />
      <Route path="users/:employeeId" element={<UserAccount />} />
      <Route path="payers" element={<Payers />} />
      <Route path="payers/new" element={<CreatePayer />} />
      <Route path="payers/:payerId/edit" element={<EditPayer />} />
      <Route path="payers/:payerId" element={<Payer />} />
      <Route path="payers/:payerId/plans/new" element={<CreatePlan />} />
      <Route path="payers/:payerId/plans/:planId" element={<Plan />} />
      <Route path="payers/:payerId/plans/:planId/edit" element={<EditPlan />} />
      <Route path="import-center" element={<ImportCenter />} />
    </Route>
  </Routes>
);
