import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useFind } from "api/apiHooks.js";
import { defaultPagingQueryConfig } from "api/apiUtils.js";

export const useBatchJobData = ({
  filters = {},
  pageNumber = 1,
  pageSize = 15,
  queryConfig = {},
}) => {
  const { authToken } = useAuthContext();
  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );
  return useFind("batch_job_data", { ...filters }, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig, ...queryConfig },
    page: { number: pageNumber, size: pageSize },
  });
};
