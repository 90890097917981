import { isHttpSuccess } from "../utils/httpStatus.js";
import { getCoreApi } from "../utils/coreApi.js";

export function fetchOrganization({
  authToken,
  filters = {},
  page = 1,
  size = 50,
  sort = undefined,
}) {
  return async () => {
    const coreApi = getCoreApi(authToken);

    try {
      const response = await coreApi.query(
        "provider",
        {
          licensed: true,
          ...filters,
        },
        {
          page: {
            number: page,
            size,
          },
          sort,
        },
      );
      if (!response || !isHttpSuccess(response.status)) {
        return { error: "Failed to fetch organizations" };
      }
      return { response };
    } catch (error) {
      return { error: error };
    }
  };
}
