import axios from "axios";
import {
  buildSerializer,
  buildJSONAPIDeserializerInterceptor,
  JSONAPIAdapter,
} from "@unite-us/json-api-resources";

const getJsonApi = (baseUrl, authToken, xEmployeeId, xProviderId) => {
  const api = axios.create({ baseURL: baseUrl });
  api.defaults.headers.common["Content-Type"] = "application/json";
  api.defaults.headers.common.Accept = "application/json";

  if (authToken) {
    api.defaults.headers.Authorization = `Bearer ${authToken}`;
  }

  if (xEmployeeId) {
    api.defaults.headers["X-EMPLOYEE-ID"] = xEmployeeId;
  }

  if (xProviderId) {
    api.defaults.headers["X-PROVIDER-ID"] = xProviderId;
  }

  return api;
};

export const getJsonApiAdapter = (
  baseUrl,
  authToken,
  xEmployeeId,
  xProviderId,
) => {
  const jsonApi = getJsonApi(baseUrl, authToken, xEmployeeId, xProviderId);
  // serializer used to serialize and deserialize api requests
  const serializer = buildSerializer();
  //set up serialization
  const jsonApiAdapter = new JSONAPIAdapter(jsonApi, serializer);
  // setup deserialization
  const jsonApiDeserializerInterceptor =
    buildJSONAPIDeserializerInterceptor(serializer);
  jsonApiAdapter.http.interceptors.response.use(jsonApiDeserializerInterceptor);
  return jsonApiAdapter;
};
