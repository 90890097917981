import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { hashToParams } from "./utils/hashToParams.js";
import { generateUUID } from "utils/generateUUID.js";
import Cookies from "js-cookie";
import { AuthLogout } from "./AuthLogout.js";

const AuthContext = createContext();

const redirectUserToAuth = (clientId, authRedirectUrl, authApiUrl) => {
  const userState = generateUUID();
  const paramsHash = {
    client_id: clientId,
    redirect_uri: authRedirectUrl,
    response_type: "code",
    scope: "app:read app:write",
    state: userState,
  };

  window.location = `${authApiUrl}/oauth2/auth?${hashToParams(paramsHash)}`;
  return;
};

const initGrantFlow = (
  cookieSessionKey,
  clientId,
  authRedirectUrl,
  authApiUrl,
) => {
  // Remove the existing session cookie and clear the session storage cache so we come back to a clean slate
  Cookies.remove(cookieSessionKey);
  sessionStorage.clear();
  redirectUserToAuth(clientId, authRedirectUrl, authApiUrl);
};

const setAuthCookie = (authResponse, cookieSessionKey) => {
  const expiresIn = authResponse.expires_in;
  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + expiresIn);
  Cookies.set(cookieSessionKey, JSON.stringify(authResponse), {
    secure: window.location.protocol === "https:",
    expires: expirationDate,
  });
};

const initSession = async (
  reqAuthParams,
  authApiUrl,
  cookieSessionKey,
  clientId,
  authRedirectUrl,
) => {
  let authResponseData;
  try {
    const authResponse = await axios.post(
      `${authApiUrl}/oauth2/token`,
      reqAuthParams,
    );
    authResponseData = authResponse.data;
    setAuthCookie(authResponse.data, cookieSessionKey);
  } catch (e) {
    console.log(e);
    // There was an authentication failure ask for credentials again
    return initGrantFlow(
      cookieSessionKey,
      clientId,
      authRedirectUrl,
      authApiUrl,
    );
  }
  return authResponseData;
};

const getAuthToken = async (
  clientId,
  cookieSessionKey,
  authRedirectUrl,
  authApiUrl,
) => {
  const authCookieJson = Cookies.get(cookieSessionKey);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  let reqAuthParams;

  //if this is a redirect from the auth server and we have a code to get the initial auth token
  if (code) {
    reqAuthParams = {
      client_id: clientId,
      redirect_uri: authRedirectUrl,
      grant_type: "authorization_code",
      code,
    };
    return initSession(
      reqAuthParams,
      authApiUrl,
      cookieSessionKey,
      clientId,
      authRedirectUrl,
    );
  } else if (authCookieJson) {
    const authCookie = JSON.parse(authCookieJson);
    // if the auth cookie has a refresh token try to authenticate with that
    if (authCookie.refresh_token) {
      return initSession(
        {
          client_id: clientId,
          refresh_token: authCookie.refresh_token,
          grant_type: "refresh_token",
        },
        authApiUrl,
        cookieSessionKey,
        clientId,
        authRedirectUrl,
      );
    } else {
      // just return the cookie if there is no refresh token?
      return authCookie;
    }
  } else {
    // ask for username and password
    return initGrantFlow(
      cookieSessionKey,
      clientId,
      authRedirectUrl,
      authApiUrl,
    );
  }
};

export const AuthContextProvider = ({
  children,
  clientId,
  cookieSessionKey,
  authRedirectUrl,
  authApiUrl,
  refreshSessionInterval,
}) => {
  const [authToken, setAuthToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");

  // when the provider is rendered kick off the auth pipeline
  useEffect(() => {
    (async () => {
      const authData = await getAuthToken(
        clientId,
        cookieSessionKey,
        authRedirectUrl,
        authApiUrl,
      );
      setAuthToken(authData?.access_token);
      setRefreshToken(authData?.refresh_token);
    })();
  }, [clientId, cookieSessionKey, authRedirectUrl, authApiUrl]);

  // when the provider is rendered start the refresh token cycle
  // this reqests a new auth token using the refresh token every  (refreshSessionInterval * 1000)
  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      const reqParams = {
        client_id: clientId,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      };
      const authData = await initSession(
        reqParams,
        authApiUrl,
        cookieSessionKey,
        clientId,
        authRedirectUrl,
      );
      setAuthToken(authData?.access_token);
      setRefreshToken(authData?.refresh_token);
    }, refreshSessionInterval * 1000);
    return () => clearInterval(refreshTokenInterval);
  }, [
    refreshSessionInterval,
    clientId,
    refreshToken,
    authApiUrl,
    cookieSessionKey,
    authRedirectUrl,
  ]);

  return (
    <AuthContext.Provider
      value={{ authToken, setAuthToken, refreshToken, setRefreshToken }}
    >
      {authToken && (
        <>
          {children}
          <AuthLogout
            authUrl={authApiUrl}
            cookieSessionKey={cookieSessionKey}
          />
        </>
      )}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
