import React, { useState } from "react";
import {
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableHeader,
  Table,
  TableBody,
} from "@unite-us/ui";
import Pagination from "components/Pagination/Pagination.js";
import Spinner from "components/Spinner/Spinner";
import { useEmployees } from "api/core/employeeHooks";
import { useAllRoles } from "api/core/roleHooks";
import { getEmployeeRoleDetails } from "../utils/getEmployeeRoleDetails";
import PropTypes from "prop-types";
import UserRecordDrawer from "./UserRecordDrawer";

export const UserRecords = ({ user }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [employeeToShowOnDrawer, setEmployeeToShowOnDrawer] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: employeeRecordsResponse,
    isFetching: isFetchingEmployeeRecords,
    isError: isErrorEmployeeRecords,
  } = useEmployees({
    filters: { user: user.id },
    pageSize,
    pageNumber,
    include: "provider",
  });
  const { data: employeeRecords, paging } = isErrorEmployeeRecords
    ? {}
    : employeeRecordsResponse;
  const {
    data: rolesResponse,
    isFetching: isFetchingRoles,
    isError: isErrorRoles,
  } = useAllRoles();
  const filteredRoles = getEmployeeRoleDetails(employeeRecords, rolesResponse);
  const isFetching = isFetchingRoles || isFetchingEmployeeRecords;
  const isError = isErrorRoles || isErrorEmployeeRecords;

  const rowClickHandler = (employee, roles) => {
    setEmployeeToShowOnDrawer({
      ...employee,
      roles: roles,
    });
    setOpenDrawer(true);
  };

  return (
    <div className="pt-4">
      {isFetching && (
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-5">
          <Spinner center />
        </div>
      )}
      {!isFetching && isError && (
        <div id="user-records-error" className="text-center">
          <div className="text-center">Error Fetching User Records</div>
        </div>
      )}
      {!isFetching && !isError && (
        <>
          <Table className="min-w-full max-h-[70%] divide-y divide-gray-200">
            <TableHeader className="bg-gray-100 sticky top-0 z-10">
              <TableRow dataTestId="user-records-table-header-row">
                <TableHeaderColumn className="text-left text-sm font-medium uppercase tracking-wider w-1/5">
                  Organization
                </TableHeaderColumn>
                <TableHeaderColumn className="text-left text-sm font-medium uppercase tracking-wider w-1/5">
                  User Status
                </TableHeaderColumn>
                <TableHeaderColumn className="text-left text-sm font-medium uppercase tracking-wider w-1/5">
                  Programs
                </TableHeaderColumn>
                <TableHeaderColumn className="text-left text-sm font-medium uppercase tracking-wider w-1/5">
                  Primary Role
                </TableHeaderColumn>
                <TableHeaderColumn className="text-left text-sm font-medium uppercase tracking-wider w-1/5">
                  Feature Roles
                </TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody className="overflow-y-auto">
              {!isFetching &&
                employeeRecords.length > 0 &&
                employeeRecords.map((record, index) => {
                  return (
                    <TableRow
                      className="bg-white hover:bg-gray-300"
                      key={record.id}
                      dataTestId={`user-records-table-row-${record.id}`}
                      clickable
                      onCellClick={() => {
                        rowClickHandler(record, filteredRoles[index]);
                      }}
                    >
                      <TableRowColumn className="whitespace-nowrap text-xs capitalize">
                        {record.provider?.name}
                      </TableRowColumn>
                      <TableRowColumn className="whitespace-nowrap text-xs capitalize">
                        {record.state}
                      </TableRowColumn>
                      <TableRowColumn className="whitespace-nowrap text-xs">
                        {record?.programs?.length}
                      </TableRowColumn>
                      <TableRowColumn className="whitespace-nowrap text-xs capitalize">
                        {filteredRoles?.length > 0 &&
                          filteredRoles[index].primaryRoles[0]?.name}
                      </TableRowColumn>
                      <TableRowColumn className="whitespace-nowrap text-xs capitalize">
                        {filteredRoles?.length > 0 &&
                          filteredRoles[index]?.featureRoles?.length}
                      </TableRowColumn>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!isFetching && employeeRecords.length === 0 && (
            <div
              className={
                "p-6 border border-t-0 border-solid border-light-border-blue"
              }
            >
              <h3 className="p-4">No Records Found</h3>
            </div>
          )}
          <div className="pt-4">
            <Pagination
              pageSize={pageSize}
              pageNumber={pageNumber}
              totalItemCount={paging.total_count}
              totalPageCount={paging.total_pages}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              showLabels={true}
            />
          </div>
          <UserRecordDrawer
            drawerOpen={openDrawer}
            setDrawerOpen={setOpenDrawer}
            employee={employeeToShowOnDrawer}
          />
        </>
      )}
    </div>
  );
};

UserRecords.propTypes = {
  user: PropTypes.object.isRequired,
};
