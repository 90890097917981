import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Tab, Tabs } from "@unite-us/ui";
import { Badge, Button, Icon } from "@unite-us/app-components";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { capitalize } from "lodash";
import { useNavigate } from "react-router";

const PlanHeader = ({ handleTabChange, location, plan, payerId, crumbs }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col items-start justify-center w-full border-b border-medium-border-blue"
      data-testid="plan-header-container"
    >
      <NavigationHeader
        crumbsData={crumbs}
        backButtonRedirectTo={`/payers/${payerId}`}
        dataTestId="plan-header-breadcrumbs"
      />

      <div className="px-20 w-full mt-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <h1 className="text-2xl">{plan.name}</h1>
            <Badge
              className={`!leading-4 !text-sm px-4 mx-2 ${stateBgColor(plan.state)}`}
              text={capitalize(plan.state)}
              color=""
              style={textColorForStyleProp(plan.state)}
            />
          </div>
          <Button
            id="edit-plan-button"
            iconLeft={<Icon color="text-blue" icon="Edit" />}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case"
            }
            label="Edit Plan"
            data-testid="edit-plan-button"
            onClick={() => navigate(`/payers/${payerId}/plans/${plan.id}/edit`)}
          />
        </div>

        <Tabs
          id="plan-tabs"
          className="mt-6"
          onChange={handleTabChange}
          value={location}
        >
          <Tab
            id="plan-overview-tab"
            label="Overview"
            value="overview"
            dataTestId="overview-tab"
          />
          <Tab
            id="plan-attachments-tab"
            label="Enrollments Attachments"
            value="attachments"
            dataTestId="enrollments-attachments-tab"
          />
        </Tabs>
      </div>
    </div>
  );
};

PlanHeader.prototype = {
  handleTabChange: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  plan: PropTypes.object.isRequired,
  payerId: PropTypes.string.isRequired,
  crumbs: PropTypes.array.isRequired,
};

export default PlanHeader;
