import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";
import {
  defaultPagingQueryConfig,
  defaultQueryConfig,
  defaultMutationConfig,
} from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const usePlans = ({
  filters = {},
  include,
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("plan", filters, jsonApiAdapter, {
    queryConfig: { enabled: filters.id !== "", ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 25 },
    sort: sort || "",
    include: include || "",
  });
};

export const useFindPlan = (planId) => {
  const { authToken } = useAuthContext();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindRecord("plan", planId, jsonApiAdapter, defaultQueryConfig);
};

export const useCreatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("plan", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("plan");
        invalidateQueries("payer");
        addToast({
          message: "Plan created successfully",
          type: "success",
          dataTestId: "create-plan-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-plan-error-toast",
        });
      },
    },
  });
};

export const useUpdatePlan = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("plan", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("plan");
        invalidateQueries("payer");
        addToast({
          message: "Plan updated successfully",
          type: "success",
          dataTestId: "update-plan-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error updating plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "edit-plan-error-toast",
        });
      },
    },
  });
};
